import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import { Checkbox } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import StatusOS from "./status_os1";

const ModalOS1 = ({ord,OrderText,handleConsultCntr}) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const typeUsr = localStorage.getItem('typeUser');
    const parsedURL = localStorage.getItem('urlParsed');



    const handleConsultContainer = async () => {
      const token = localStorage.getItem('token');
      let apiUrl = '';
      console.log(typeUsr);
      switch (typeUsr) {
        case '90':
          apiUrl = `${parsedURL}get/containers_status/specific?id_order=${ord}`;
          break;
        case '120':
          apiUrl = `${parsedURL}get/containers_status/specific?id_order=${ord}`;
          break;
        case '100':
          apiUrl = `${parsedURL}get/containers_status/specific?id_order=${ord}`;
          break;
        case '110':
          apiUrl = `${parsedURL}get/containers_status/specific?id_order=${ord}`;
          break;
        default:
          break;
      }
  
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error('Ocorreu um erro ao buscar os dados:', error);
      }
    };
  
    useEffect(() => {
      handleConsultContainer();
    }, [ord]);
  
    const handleCheckboxChange = (id) => {
      setData((prevData) => {
        const updatedData = prevData.map((row) =>
          row.id === id ? { ...row, selected: !row.selected } : row
        );
        return updatedData;
      });
    };
  

    const columns = [
      {
        field: "id",
        headerName: "ID",
        width: 90 },
      { field: "nracond", headerName: "Container", width: 150 },
      { field: "totalfardos", headerName: "Qtd de Fardos", width: 150 },
      { field: "dtpesagem", headerName: "Data da Estufagem", width: 150 },
      {
        field: "verCNTR",
        headerName: "Ver Dados de Estufagem",
        width: 150,
        renderCell: (params) => (
          <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" onClick={() => handleConsultCntr(params.row.codacond,params.row.nracond)} variant="contained" color="primary">
           <SearchIcon/>
          </button>
        ),
      },
    ];
  
    const rows = data.map((row,index) => ({
      id: index + 1,
      codacond: row.codacond,
      nracond: row.nracond,
      totalfardos: row.totalfardos,
      dtpesagem: row.dtpesagem,
    }));
  
    console.log(data);
  

  return (
        <>
        <div className="">
                <label for="razaosocial" class="hsx1 ">Ordem de Serviço:</label>
                <input type="text" class="border mx-2" id="razaosocial" value={OrderText} readOnly={true}/>
        </div>
        <div className="my-3 transparent-input" style={{ height: 288, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
        </>
  );
};

export default ModalOS1;