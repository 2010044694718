import React from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import Frase from "./frase";
import CabecalhoCe from "./cabecalho";
import ContainerTruck from "./container_truck";
import CadLacre from "./cad_lacre";
import VerPend from "./ver_pendencias";
import ProtocoloEnt from "./prot_entrega";
import NonWood from "./non_wood";

const CadastroCertificado = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const osd = searchParams.get('idos');

  return (
        <>
        <Navbar></Navbar>
        <div className="box-center">
            <div>
        <form class="row g-3">
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Instrução de Embarque</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Exportador</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Cliente</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Terminal</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Qnt. Fardos</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="nomefantasia" class="form-label">Úlitmo dia de Serviço</label>
    <input type="date" class="form-control transparent-input " id="nomefantasia"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Envio Peso</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Envio Draft</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Emissão do Original</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-3">
    <label for="razaosocial" class="form-label ">Referência do Cliente</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-3">
    <label for="razaosocial" class="form-label ">Cidade de Execução do Serviço</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  
  </form>
  </div>
        <nav>
            <div class="nav nav-tabs my-3" id="nav-tab" role="tablist">
                <button class="nav-link active form-label" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-cabecalho" type="button" role="tab" aria-controls="nav-cabecalho" aria-selected="true">Cabeçalho</button>
                <button class="nav-link form-label" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-nb" type="button" role="tab" aria-controls="nav-nb" aria-selected="false">NB</button>
                <button class="nav-link form-label" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-cont_truck" type="button" role="tab" aria-controls="nav-cont_truck" aria-selected="false">Containers/Trucks</button>
                <button class="nav-link form-label" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-cad_lacre" type="button" role="tab" aria-controls="nav-cad_lacre" aria-selected="true">Cadastro de Lacres</button>
                <button class="nav-link form-label" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-ver_pend" type="button" role="tab" aria-controls="nav-ver_pend" aria-selected="false">Verificar Pendências</button>
                <button class="nav-link form-label" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-protocolo" type="button" role="tab" aria-controls="nav-protocolo" aria-selected="false">Protocolo de Entrega</button>
                <button class="nav-link form-label" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-nonwood" type="button" role="tab" aria-controls="nav-nonwood" aria-selected="false">Non Wood Packing Certificate</button>
            </div>
        </nav>
            <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active " id="nav-cabecalho" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
            <CabecalhoCe></CabecalhoCe>
            </div>
            <div class="tab-pane fade" id="nav-nb" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
            <Frase></Frase>
            </div>
            <div class="tab-pane fade" id="nav-cont_truck" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
            <ContainerTruck></ContainerTruck>
            </div>
            <div class="tab-pane fade" id="nav-cad_lacre" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
            <CadLacre></CadLacre>
            </div>
            <div class="tab-pane fade" id="nav-ver_pend" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
            <VerPend></VerPend>
            </div>
            <div class="tab-pane fade" id="nav-protocolo" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
           <ProtocoloEnt></ProtocoloEnt>
            </div>
            <div class="tab-pane fade" id="nav-nonwood" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
            <NonWood></NonWood>
            </div>
            </div>
            </div>
        </>
  );
};

export default CadastroCertificado;