import React, { useEffect, useState } from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";
import "../../style/style.css"
import LoteProc2 from "./lote2_proc";


const LoteProc = ({ondataos,onSetDataOs,onhandleChange}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const codos = searchParams.get('codos');
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const codoffice = localStorage.getItem('codoffice');

  const [data, setData] = useState([]);
  const [cont, setCont] = useState("");
  const [typeBale, setTypeBale] = useState("");


  useEffect(() => {
  }, []);

  const columns = [
    { field: "lote", headerName: "Lote", width: 150, editable: true, filter: false},
    { field: "tara", headerName: "Tara", width: 150, cellEditor: 'agNumberCellEditor' , 
    editable: true, 
    cellEditorParams: {
      precision: 2,
      step: 0.01,
      showStepperButtons: true
    } 
  }
  ];

  const onAddRow = () => {
    const newRow = {
      lote: "",
      tara: parseFloat("0.0"),
    };
    setData([...data, newRow]);
  };

    const onRemoveRows = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    const updatedData = data.filter(row => !selectedData.includes(row));
    setData(updatedData);
  };
  const gridRef = React.useRef(); 

  const handleSaveLots = async () => {

    const mapLoteToDict = (lote) => {
      return {
        lote: lote.lote,  
        tara: lote.tara,  
      };
    };
    const cleanedLotes = data.map(mapLoteToDict);

    const isBlank = (value) => {
      return value === null || value === undefined || value === '';
    };

    // Dados a serem enviados ao backend
    const payload = {
      codcliente: ondataos.codCliente,
      codprodutor: ondataos.codProdutor,
      contrato: isBlank(ondataos.contrato) ? 'Sem Contrato' : ondataos.contrato,
      safra: ondataos.safra,
      tipofardo: isBlank(ondataos.tipofardo) ? 'Sem Tipo' : ondataos.tipofardo,
      lotes: cleanedLotes,  
    };
    
    try {
      // Enviar uma solicitação POST ao endpoint do backend
      const response = await axios.post(`${parsedURL}post/cad_os/lotes?codos=${codos}`,
       payload,
       {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }
      );
      
      if (response.status === 200) {
        console.log("Lotes salvos com sucesso:", response.data);
        window.location.reload();
      } else {
        console.error("Erro ao salvar os lotes");
      }
    } catch (error) {
      console.error("Erro ao enviar a solicitação:", error);
    }
  };

  return (
    <>
      <div class="modal fade" id="LoteProc" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Cadastrar Lotes</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="container">
                <div class="row align-items-start">
                  <div class="col-5">
                    <form class="row">
                      <div class="mb-3">
                        <label class="form-label2">Cliente</label>
                        <div class="input-group ">
                          <input type="text" readOnly={true} class="form-control" value={ondataos.cliente}/>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="form-label2">Produtor</label>
                        <div class="input-group">
                          <input type="text" readOnly={true} class="form-control" value={ondataos.produtor}/>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label2">Safra</label>
                        <input type="text transparent-input" class="form-control" readOnly={true} value={ondataos.safra}/>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label2">Contrato</label>
                        <input type="text transparent-input" class="form-control"/>
                      </div>
                      <div class="col-md-6">
                        <label  class="form-label2">Tipo do Fardo</label>
                        <input type="text" class="form-control"/>
                      </div>
                    </form>
                    <div><hr class="divider " /></div>
                    <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columns}
                        rowSelection="multiple"
                      />
                    </div>
                    <div class="col-12 p-3">
                    <button type="button" className="btn btn-success" onClick={onAddRow}>+</button>
                      <button type="button" className="btn mx-2 btn-danger" onClick={onRemoveRows}>-</button>
                    </div>
                  </div>
                  <div class="col-6">
                    <LoteProc2></LoteProc2>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" onClick={handleSaveLots}>Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoteProc;