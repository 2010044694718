import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Search from "@mui/icons-material/Search";
import BuscarDados from "./buscardados";

const DemoCalculo = () => {
  const navigate = useNavigate();

  return (
        <>
        
        <div class="row align-items-start">
            <div class="col-8">
            <form class="row g-3">
            <div class="col-md-7">
                <label for="inputEmail3" class="col-sm-2 form-label">OS</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2"><Search></Search></button>
            </div>
            </div>
                </form>
            </div>
            <div class="col">
            <form class="row g-3">
                <div class="col-md-6">
                    <label for="razaosocial" class="form-label ">NF-e</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">RPS</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-6">
                    <label for="razaosocial" class="form-label ">Emissão NF-e</label>
                    <input type="datetime-local" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Emissão RPS</label>
                    <input type="datetime-local" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                </form>
            </div>
        </div>
        <div><hr class="divider bg-custom "/></div>
        <div className="hfx">Dados do tomador de Serviço</div>
        <form class="row g-3 my-1">
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label ">Razão Social / Nome</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4">
                    <label for="nomefantasia" class="form-label">CNPJ / CPF</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div className="col-md-4">
                <label for="razaosocial" class="form-label ">Inscrição Municipal</label>
                <div class="input-group">
                    <input type="text" class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon"/>
                    <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Mudar Cliente</button>
                    </div>
                    </div>
                <div class="col-md-5">
                    <label for="razaosocial" class="form-label">Município</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-7">
                    <label for="nomefantasia" class="form-label">Endereço e CEP</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-4">
                    <label for="nomefantasia" class="form-label">Complemento</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-4">
                    <label for="nomefantasia" class="form-label">Telefone</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-4">
                    <label for="nomefantasia" class="form-label">E-mail</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                </form>
        <div><hr class="divider bg-custom "/></div>
        <div class="row align-items-start">
        <div className="hfx">Cálculo</div>
            <div class="col-8">
           <form className="row g-3 my-1">
                <div className="col-md-6">
                <label for="razaosocial" class="form-label ">CNPJ de Proposta</label>
                <div class="input-group">
                    <input type="text" class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon"/>
                    <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Buscar</button>
                    </div>
                    </div>
                    <div className="col-md-6">
                <label for="razaosocial" class="form-label ">.</label>
                <div class="input-group">
                <select class="form-select" aria-label="Default select example">
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </select>
                    <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Selecionar</button>
                    </div>
                    </div>
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label ">Valor Unitário(R$)</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label ">Qntd de Containers</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label ">Qntd de Unidades</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-8">
                    <label for="razaosocial" class="form-label ">Valor Total</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label ">Data de Vencimento</label>
                    <input type="date" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label ">PIS</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label ">COFINS</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label ">IR</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label ">CSLL</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-6">
                    <label for="razaosocial" class="form-label ">Valor Liquído</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div className="col-md-6 text-center pt-4">
                <div class="form-check form-check-inline form-check">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                <label class="form-check-label hfx" for="inlineRadio1">Quantidade de Fardos</label>
                </div>
                <div class="form-check form-check-inline form-check">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                <label class="form-check-label hfx" for="inlineRadio2">Containers</label>
                </div>
                </div>
           </form>
            </div>
            <div class="col">
            <div class="col-md-12">
                    <label for="exampleFormControlTextarea1" class="form-label">Discriminação dos Serviços</label>
                    <textarea class="form-control transparent-input" id="exampleFormControlTextarea1" rows="16"></textarea>
                    </div>
            </div>
        </div>
        <div><hr class="divider bg-custom "/></div>
        <div class="col-12">
                <button type="submit" class="btn btn-success">Calcular</button>
                <button type="submit" class="btn mx-2 btn-warning">Limpar Cálculo</button>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#buscardados">Buscar</button>
            <BuscarDados></BuscarDados>
            </div>
        </>
  );
};

export default DemoCalculo;