import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import { Checkbox } from "@mui/material";

const ModalOS2 = ({codAcond,numberAcod}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const typeUsr = localStorage.getItem('typeUser');
  const parsedURL = localStorage.getItem('urlParsed');

  
  
  
   
  const handleConsultContainer = async () => {
    const token = localStorage.getItem('token');
    let apiUrl = '';
    console.log(typeUsr);
    switch (typeUsr) {
      case '90':
        apiUrl = `${parsedURL}get/containers_datails/specific?id_acond=${codAcond}`;
        break;
      case '120':
        apiUrl = `${parsedURL}get/containers_datails/specific?id_acond=${codAcond}`;
        break;
      case '100':
        apiUrl = `${parsedURL}get/containers_datails/specific?id_acond=${codAcond}`;
        break;
      case '110':
        apiUrl = `${parsedURL}get/containers_datails/specific?id_acond=${codAcond}`;
        break;
      default:
        break;
    }
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os dados:', error);
    }
  };

  useEffect(() => {
    handleConsultContainer();
    console.log(codAcond);
  }, [codAcond]);

  const handleCheckboxChange = (id) => {
    setData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.id === id ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    { field: "nf", headerName: "Nota Fiscal", width: 150 },
    { field: "lote", headerName: "Lote", width: 150 },
    { field: "fardos", headerName: "Qtd de Fardos", width: 150 }
  ];

  const rows = data.map((row,index) => ({
    id: index + 1,
    nf: row.nf,
    lote: row.lote,
    fardos: row.fardos,
  }));

  console.log(data);
  console.log(numberAcod);
  return (
        <>
        <div className="">
                <label for="razaosocial" class="hsx1 ">Numeração:</label>
                <input type="text" class="border mx-2" id="razaosocial" value={numberAcod}/>
        </div>
      <div><hr className="divider bg-custom" /></div>
        <div className="my-3 transparent-input" style={{ height: 260, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
        </>
  );
};

export default ModalOS2;