import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const VerPend = () => {
  const navigate = useNavigate();

  return (
        <>
        <div class="col-md-12">
                <textarea class="form-control transparent-input" id="exampleFormControlTextarea1" rows="8"></textarea>
                </div>
                <button type="submit" class="btn btn-warning my-2">Verificar Pendencias</button>
                <div class="col-12">
                    <button type="submit" class="btn btn-success">Salvar</button>
                    <button type="submit" class="btn mx-2 btn-primary">Imprimir</button>
                </div>
        
        </>
  );
};

export default VerPend;