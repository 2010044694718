import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../style/style.css"
import { Checkbox } from "@mui/material";
import Search from "@mui/icons-material/Search";

const PesquisaEntidade = ({ontipo, onSetDataOs, onCopyCPE}) => {
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(""); // novo estado para armazenar o texto do input
  const [razaoChecked, setRazaoChecked] = useState(false); // para o checkbox Razão Social
  const [fantasiaChecked, setFantasiaChecked] = useState(false);
  const [tipo, setTipo] = useState(""); // para o checkbox Fantasia
  const [selectedRow, setSelectedRow] = useState(null); // para manter a linha selecionada


  useEffect(() => {
    setRazaoChecked(true);
    
  }, []);
  
  
  const handleGetEntidade = async () => {
    let type = null;
    if (razaoChecked) {
      type = 'razao';
    } else {
      type = 'fantasia';
    }

    const tip = ontipo;
    try {
      const response = await axios.get(
        `${parsedURL}get/cad_os/buscar_entidade/${tip}?entidade=${searchText}&type=${type}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setData(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  const handleRowDoubleClick = (params) => {
    const selectedItem = params.data;
    console.log(ontipo);
    console.log("Row double-clicked:", selectedItem);
    let newValue;
    if (ontipo == 'cliente') {
      if (onCopyCPE) {
       newValue = ({
        codCliente: selectedItem.codentidade,
        cliente: selectedItem.fantasia,
        codExportador: selectedItem.codentidade,
        exportador:selectedItem.fantasia,
        codProdutor: selectedItem.codentidade,
        produtor:selectedItem.fantasia,
      }
        );
      } else {
         newValue = ({
          codCliente: selectedItem.codentidade,
          cliente: selectedItem.fantasia
        }
          );
      }
        }else if (ontipo == 'exportador') {
         newValue = ({
          codExportador: selectedItem.codentidade,
          exportador: selectedItem.fantasia
        }
          );
        } 
        else if (ontipo =='produtor') {
          newValue = ({
            codProdutor: selectedItem.codentidade,
            produtor: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'terminal') {
          newValue = ({
            codTerminal: selectedItem.codentidade,
            terminal: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'despachante') {
          newValue = ({
            codDespachante: selectedItem.codentidade,
            despachante: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'corretor') {
          newValue = ({
            codCorretor: selectedItem.codentidade,
            corretor: selectedItem.fantasia
         }
           );
         }
         else if (ontipo == 'codFaturarP') {
          newValue = ({
            codFaturarP: selectedItem.codentidade,
            faturar: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'CodEnviarP') {
          newValue = ({
            CodEnviarP: selectedItem.codentidade,
            enviar: selectedItem.fantasia
         }
           );
         }  
         else {
          newValue = ({
            codTrader: selectedItem.codentidade,
            trader: selectedItem.fantasia
         }
           );
         }; 
         onSetDataOs((prevDataOs) => ({
          ...prevDataOs, // Preserve existing data
          ...newValue, // Add/overwrite with new data
        }));
        const modalElement = document.getElementById('staticBackdrop');
        if (modalElement) {
          const modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement);
          modalInstance.hide();
          setData([]);
          // Remova a classe 'show' do modal
          modalElement.classList.remove('show');
      
          // Altere o estilo do modal para escondido
          modalElement.style.display = 'none';
      
          // Altere atributos para tornar o modal "inativo"
          modalElement.setAttribute('aria-modal', 'false');
          modalElement.setAttribute('aria-hidden', 'true');
      
          // Remova backdrop se necessário
          const backdropElement = document.querySelector('.modal-backdrop');
          if (backdropElement) {
            backdropElement.remove();
          }
      
          document.body.classList.remove('modal-open');
        }
  };


  const removeModalBackdrop = () => {
    const backdropElement = document.querySelector('.modal-backdrop');
    if (backdropElement) {
        backdropElement.remove();
    }
};

  const handleRowClick = (params) => {
    const selectedItem = params.data;
    console.log(ontipo);
    console.log("Row double-clicked:", selectedItem);
    let newValue;
    if (ontipo == 'cliente') {
      if (onCopyCPE) {
       newValue = ({
        codCliente: selectedItem.codentidade,
        cliente: selectedItem.fantasia,
        codExportador: selectedItem.codentidade,
        exportador:selectedItem.fantasia,
        codProdutor: selectedItem.codentidade,
        produtor:selectedItem.fantasia,
      }
        );
      } else {
         newValue = ({
          codCliente: selectedItem.codentidade,
          cliente: selectedItem.fantasia
        }
          );
      }
        }else if (ontipo == 'exportador') {
         newValue = ({
          codExportador: selectedItem.codentidade,
          exportador: selectedItem.fantasia
        }
          );
        } 
        else if (ontipo =='produtor') {
          newValue = ({
            codProdutor: selectedItem.codentidade,
            produtor: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'terminal') {
          newValue = ({
            codTerminal: selectedItem.codentidade,
            terminal: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'despachante') {
          newValue = ({
            codDespachante: selectedItem.codentidade,
            despachante: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'corretor') {
          newValue = ({
            codCorretor: selectedItem.codentidade,
            corretor: selectedItem.fantasia
         }
           );
         }
         else if (ontipo == 'codFaturarP') {
          newValue = ({
            codFaturarP: selectedItem.codentidade,
            faturar: selectedItem.fantasia
         }
           );
         } 
         else if (ontipo == 'codFaturarP2') {
          newValue = ({
            codFaturarP2: selectedItem.codentidade,
            enviar: selectedItem.fantasia
         }
           );
         }  
         else {
          newValue = ({
            codTrader: selectedItem.codentidade,
            trader: selectedItem.fantasia
         }
           );
         }; 
         onSetDataOs((prevDataOs) => ({
          ...prevDataOs, // Preserve existing data
          ...newValue, // Add/overwrite with new data
        }));
  };

  const handleConfirmClick = () => {
    if (selectedRow) {
      console.log("Confirm action:", selectedRow);
      // Executar ação desejada com o item selecionado
    } else {
      console.log ("No row selected.");
    }
    setData([]);
  };

  const handleRazaoChange = () => {
    setRazaoChecked(!razaoChecked);
    if (fantasiaChecked) {
      setFantasiaChecked(false); // desmarcar o outro checkbox
    }
  };

  const handleFantasiaChange = () => {
    setFantasiaChecked(!fantasiaChecked);
    if (razaoChecked) {
      setRazaoChecked(false); // desmarcar o outro checkbox
    }
  };

  const columns = [
    { field: "codentidade", headerName: "Cod", width: 80 },
    { field: "razao", headerName: "Razão Social", width: 150 },
    { field: "fantasia", headerName: "Fantasia", width: 150 },
    { field: "Tipo", headerName: "Tipo", width: 150 },
    { field: "cidade", headerName: "Cidade", width: 150 },
    { field: "uf", headerName: "UF", width: 150 }
  ];

  return (
    <>
      <div class="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content bg-body-secondary">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Pesquisa de Entidade</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="row g-3">
                <div class="col-md-6">
                <input
                    type="text"
                    class="form-control transparent-input"
                    value={searchText} // ligar o valor do input ao estado
                    onChange={(e) => setSearchText(e.target.value)} // atualizar o estado conforme o texto muda
                  />
                </div>
                <div class="form-check col-md-2 my-4 mx-4">
                <input
                    class="form-check-input transparent-input"
                    type="checkbox"
                    checked={razaoChecked} // ligar ao estado
                    onChange={handleRazaoChange} // chamar função de alternância
                  />
                  <label class="" for="gridCheck">
                    Razão Social
                  </label>
                </div>
                <div class="form-check col-md-2 my-4">
                <input
                    class="form-check-input transparent-input"
                    type="checkbox"
                    checked={fantasiaChecked} // ligar ao estado
                    onChange={handleFantasiaChange} // chamar função de alternância
                  />
                  <label class="" for="gridCheckChecked">
                    Fantasia
                  </label>
                </div>
                <div class="col-md-1">
                  <button type="button" class="btn btn-primary" onClick={() => handleGetEntidade()}><Search/></button>
                </div>
                {/*<div class="col-md-5 ">
                  <label for="inputState" class="form-label ">Tipo de entidade</label>
                  <select id="inputState" class="form-select transparent-input">
                    <option selected></option>
                    <option>CPF</option>
                    <option>CNPJ</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label for="telefone" class="form-label">Estado</label>
                  <input type="text" class="form-control transparent-input" id="telefone" />
                </div>
                <div class="form-check col-md-2 my-5 mx-4">
                  <input class="form-check-input transparent-input" type="checkbox" id="gridCheck" />
                  <label class="" for="gridCheck">
                    Inadimplente
                  </label>
                </div>*/}
                <div><hr class="divider " /></div>
                <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                  <AgGridReact
                    rowData={data}
                    columnDefs={columns}
                    rowSelection="single"
                    onRowDoubleClicked={handleRowDoubleClick} // Clique duplo
                    onRowClicked={handleRowClick} // Clique simples para seleção
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleConfirmClick}>Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PesquisaEntidade;