import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../style/style.css";
import { NavigateNext, Search } from '@mui/icons-material';
import InputIcon from '@mui/icons-material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PesagemModal1 from "./pesagem_modal1";
import PesagemModal2 from "./pesagem_modal2";
import axios from "axios";

const PesagemSaida = () => {
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [dadosCntr, setDadosCntr] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const osd = searchParams.get('idos');
  const [codAcond, setCodAcond] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    handleGetContainers();

  }, []);

  const handleGetContainers = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/planilha_peso/containers?codos=${osd}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setDadosCntr(response.data);
      }
    } catch (error) {
      console.log(error)
    }

  }

  const handleDeleteContainer = async (params) => {
    console.log(params);
    const codacond = params.data.codacond;

    try {
      const response = await axios.delete(
        `${parsedURL}delete/planilha_peso/deletar_container?codAcond=${codacond}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        handleGetContainers();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleConsultModal = (params) => {
    const acc = params.data.codacond;
    console.log(acc)
    setCodAcond(acc);
  }


  const handleCheckboxChange = () => {
    const nracond = inputValue;
    dadosCntr((prevData) => {
      const updatedData = prevData.map((row) =>
        row.nracond === nracond ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const handleAddRow = () => {
    const nracond = inputValue;
    setDadosCntr((prevData) => [
      ...prevData,
      {
        nracond: nracond,
        tipoacond: "CNTR",
        tipoLoad: "FULL",
        totalfardos: 0,
        fardos: 0,
        fullTruck: 0.0,
        cargoGross: 0.0,
        cntrTare: 0.0,
        grossWeight: 0.0,
        dtpesagem: "",
        dtsaivda: "",
        sealno: "",
        sealk: "",
        nomeUser: "",
        codacond: ""
      },
    ]);
  };


  const handleSalvar = async () => {
    try {
      const response = await axios.post("sua_url_endpoint", dadosCntr, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Dados enviados com sucesso:", response.data);
      // Lógica adicional após o sucesso da solicitação
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
      // Lógica adicional para lidar com erros
    }
  };

  const calculateColumnSum = (columnName) => {
    if (!dadosCntr || dadosCntr.length === 0) {
      return 0; // Retorna 0 se não houver dados ainda
    }
    return dadosCntr.reduce((sum, currentRow) => {
      return sum + parseFloat(currentRow[columnName] || 0);
    }, 0).toFixed(2);
  };

  const dataWithTotalRow = [...dadosCntr, {
    nracond: 'Total',
    totalfardos: calculateColumnSum("totalfardos"),
    fardos: calculateColumnSum("fardos"),
    grossWeight: calculateColumnSum("grossWeight"),
  }];

  const handleChangeContainer = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setIsValid(erNumberValidation(value));
  };

  const dtContainers = [
    {
      field: "nracond", headerName: "Container",
      filter: true,
      width: 150,
      editable: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === 'Total' ? 'lightblue' : 'inherit', // Cor de fundo diferente se for 'Total'
        fontWeight: params.value === 'Total' ? 'bold' : 'normal', // Fonte em negrito se for 'Total'
      }),
      valueFormatter: (params) => params.value || ""
    },
    {
      field: "tipoacond", headerName: "Tipo",
      filter: false,
      width: 130,
      cellRenderer: (params) => (
        params.data.nracond === 'Total' ? '' :
        <select
          id="inputState"
          className="form-select"
        >
          <option value="FULL">CNTR</option>
          <option value="PART OF">TRUCK</option>
        </select>
      ),
      valueFormatter: (params) => params.value || "",
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      }),

    },
    {
      field: "tipoLoad", 
      headerName: "Carregamento", 
      filter: false, 
      width: 150, 
      cellRenderer: (params) => ( 
        params.data.nracond === 'Total' ? '' :
        <select
          id="inputState"
          className="form-select"
        >
          <option value="FULL">FULL</option>
          <option value="PART OF">PART OF</option>
        </select>
      ), 
      valueFormatter: (params) => params.value || "",
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      }),

    },
    {
      field: "totalfardos", 
      headerName: "Total de Fardos", 
      filter: true, 
      width: 150, 
      editable: true, 
      valueFormatter: (params) => params.value || "0",
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      }),
    },
    {
      field: "fardos", headerName: "Fardos Utilizados", filter: true, width: 150, editable: true, valueFormatter: (params) => params.value || "0", cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: "fullTruck", 
      headerName: "Full Truck", 
      filter: true, 
      width: 150, 
      editable: true, 
      valueFormatter: (params) => params.value || "0,0", 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "emptyTruck", 
      headerName: "Truck Tare",
      filter: true, 
      width: 150, 
      editable: true, 
      valueFormatter: (params) => params.value || "0,0", 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "cargoGross", 
      headerName: "Gross Cargo", 
      filter: true, 
      width: 150, 
      editable: true, 
      valueFormatter: (params) => params.value || "0,0", 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "cntrTare", 
      headerName: "Cntr Tare", 
      filter: true, 
      width: 150, 
      editable: true, 
      valueFormatter: (params) => params.value || "0,0", 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "grossWeight", 
      headerName: "Gross Weight", 
      filter: true, 
      width: 150, 
      editable: true, 
      valueFormatter: (params) => params.value || "0,0", 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "dtpesagem",
      headerName: "Data Pesagem",
      filter: true, width: 150,
      cellRenderer: (params) => (
        params.data.nracond === 'Total' ? '' :
        <input type="date" className="form-control" value={params.value ? params.value.split('/').reverse().join('-') : ''} ></input>
      ),
      valueFormatter: (params) => params.value || ""
      , cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "dtsaida",
      headerName: "Data Saída Cntr",
      filter: (params) =>  (params.data.nracond === 'Total' ? '' : true) ,
      cellRenderer: (params) => (
        params.data.nracond === 'Total' ? '' :
        <input type="date" className="form-control" value={params.value ? params.value.split('/').reverse().join('-') : ''} ></input>
      ),
      valueFormatter: (params) => params.value || "", 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "sealno", 
      headerName: "Seal Shipper", 
      filter: true, 
      width: 150, 
      valueFormatter: (params) => params.value || "", 
      editable: true, cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "sealk", 
      headerName: "Seal Surveyos", 
      filter: true, 
      width: 150, 
      valueFormatter: (params) => params.value || "", 
      editable: true, cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "nomeUser", 
      headerName: "Usuario", 
      filter: true, 
      width: 150, 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "codacond", 
      headerName: "Visulizar", 
      filter: true, 
      width: 125, 
      cellRenderer: (params) => (
        params.data.nracond === 'Total' ? '' :
        <button type="submit" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#PesagemModal1" onClick={() => handleConsultModal(params)}><EditIcon /></button>
      ),
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "codacond", 
      headerName: "Alocar NF", 
      filter: true, 
      width: 150, 
      cellRenderer: (params) => (
        params.data.nracond === 'Total' ? '' :
        <button type="submit" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#PesagemModal1" ><InputIcon /></button>
      ), 
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },

    {
      field: "codacond", 
      headerName: "Deletar", 
      filter: true, 
      width: 125, 
      cellRenderer: (params) => (
        params.data.nracond === 'Total' ? '' :
        <button
          type="submit"
          className="btn btn-outline-primary btn-sm"
          onClick={() => handleDeleteContainer(params)}
        >
          <DeleteIcon />
        </button>
      ),
      cellStyle: (params) => ({
        backgroundColor: params.data.nracond === 'Total' ? 'lightblue' : 'inherit', // Aplica estilo se nracond for 'Total'
      }),

    }
  ];

  return (
    <>
      <PesagemModal1
        codAcond={codAcond}
      />
      <div className="ag-theme-quartz" style={{ height: 300 }}>
        <AgGridReact
          rowData={dataWithTotalRow}
          columnDefs={dtContainers}
          rowSelection="unique"
          onSelectionChanged={() => console.log('Row Selected!')}
        />
      </div>
      <button type="button" class="my-2 btn btn-success" data-bs-target="#add_container" data-bs-toggle="modal"><AddIcon /></button>
      {/*<div class="row g-1">
        <div class="col-md-2">
          <label for="emailfat" class="form-label hfx4">Fardos Utilizados</label>
          <input type="text" readOnly="false" value={totalFardosUtilizados} class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-2">
          <label for="emailfat" class="form-label hfx4">Gross Weigth</label>
          <input type="text" readOnly="false" value={totalGrossWeight} class="form-control transparent-input rounded form-control-sm" />
        </div>
      </div>*/}
      <button type="button" class="my-2 btn btn-success" >Abrir planilhas de container/Lacres</button>
      <div><hr class="divider bg-custom" /></div>
      <PesagemModal2 />
      {/*<div class="row g-1">
        <div class="col-md-2">
          <label for="emailfat" class="form-label hfx4">Fardos NF</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-2">
          <label for="emailfat" class="form-label hfx4">Disponiveis</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
    </div>*/}
      <button
        type="button"
        className="my-2 btn btn-success"
        onClick={handleSalvar}
      >
        Salvar
      </button>
      <div class="modal fade" id="add_container" aria-hidden="true" aria-labelledby="add_container" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="add_container">Cadastrar Container</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className={`form-control transparent-input rounded form-control-sm ${isValid ? '' : 'is-invalid' // Adiciona a classe 'is-invalid' se a entrada não for válida
                  }`}
                value={inputValue}
                onChange={handleChangeContainer}
              />
              {!isValid && <div className="invalid-feedback">Número de contêiner inválido.</div>}
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-secondary"
                data-bs-dismiss={isValid ? "modal" : ""}
                onClick={isValid ? handleAddRow : null} // Apenas chama a função handleAddContainer se o número do contêiner for válido
              >
                Cadastrar Container
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PesagemSaida;

function erNumberValidation(cntrNbr) {
  const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const CODES = [
    10, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    32, 34, 35, 36, 37, 38
  ];

  if (cntrNbr.startsWith('XXXX')) {
    return true;
  }

  // Remove espaços e hífens do número do contêiner
  cntrNbr = cntrNbr.replace(/\s/g, '').replace(/-/g, '');
  let intSoma = 0;
  let intIndice = 1;

  for (let i = 0; i < 11; i++) {
    let intCodeNumber;
    if (i < 4) {
      intCodeNumber = CODES[ALPHA.indexOf(cntrNbr[i])];
    } else {
      intCodeNumber = parseInt(cntrNbr[i]) || 0;
    }

    if (i < 10) {
      intSoma += intCodeNumber * intIndice;
      intIndice *= 2;
    }
  }

  intIndice = intSoma % 11;
  if (intIndice === 10) {
    intIndice = 0;
  }
  console.log(`${intIndice} = ${parseInt(cntrNbr[10])}`)
  return intIndice === parseInt(cntrNbr[10]);
}