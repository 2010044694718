import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../style/style.css";
import { NavigateNext, Search } from '@mui/icons-material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PLModal from "./packing_list_modal";
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const PackingList = () => {
  const [packingList, setPackingList] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const osd = searchParams.get('idos');
  const [baleId, setBaleId] = useState(false);
  const [kgs, setKgs] = useState(false);
  const [tara, setTara] = useState(true);
  const [lote, setLote] = useState(true);
  const [separaSaiCode, setSeparaSaiCode] = useState(false);
  const [qtdDigits, setQtdDigits] = useState(20);
  const [codAloca, setCodAloca] = useState(null);

  useEffect(() => {
    handleGetPackingList();
  }, []);

  const handleGetPackingList = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/planilha_peso/packing_list?codos=${osd}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setPackingList(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleButtonClick = (params) => {
    const aloc = params.data.codaloca;
    setCodAloca(aloc);
    console.log(aloc);

  };


  const colDefs = [
    { field: "nracond", headerName: "Container", filter: true, width: 150 },
    { field: "nf", headerName: "Nota", filter: true, width: 120 },
    { field: "lote", headerName: "Lote", filter: true, width: 120 },
    { field: "fardos", headerName: "Fardos", filter: true, width: 120, cellStyle: { 'background-color': 'goldenrod' } },
    { field: "GrossWeight", headerName: "Peso Bruto", filter: true, width: 120, cellStyle: { 'background-color': 'goldenrod' }, aggFunc: 'sum' },
    { field: "TareCargo", headerName: "Tara", filter: true, width: 120, cellStyle: { 'background-color': 'goldenrod' }, aggFunc: 'sum' },
    { field: "NetWeight", headerName: "Peso Liquido", filter: true, width: 120, cellStyle: { 'background-color': 'goldenrod' }, aggFunc: 'sum' },
    { field: "media_fardo", headerName: "Media Fardo", filter: true, width: 120, cellStyle: { 'background-color': 'skyblue' } },
    { field: "UnitTare", headerName: "Tara Unitaria", filter: true, width: 120, cellStyle: { 'background-color': 'skyblue' } },
    { field: "FardosPacking", headerName: "Fardos PL", filter: true, width: 120, cellStyle: { 'background-color': 'fuchsia' }, aggFunc: 'sum', valueParser: "Number(newValue)" },
    { field: "GrossPacking", headerName: "Peso Bruto PL", filter: true, width: 120, cellStyle: { 'background-color': 'fuchsia' }, aggFunc: 'sum' },
    { field: "TarePacking", headerName: "Tara PL", filter: true, width: 120, cellStyle: { 'background-color': 'fuchsia' }, aggFunc: 'sum' },
    { field: "NetPacking", headerName: "Peso Liquido PL", filter: true, width: 120, cellStyle: { 'background-color': 'fuchsia' } },
    { field: "DataSaidaCntr", headerName: "Data de Saida CNTR", filter: true, width: 120 },
    {
      field: "codaloca", headerName: "Ver Lista", filter: false, width: 150, cellRenderer: (params) => (
        <div class="d-grid gap-2 col-6 mx-auto">
          <button type="submit" class="btn btn-outline-info btn-sm" data-bs-toggle="modal" data-bs-target="#PLModal" onClick={() => handleButtonClick(params)} ><ListAltIcon /></button>
        </div>
      )
    },
    {
      field: "", headerName: "Inserir Fardos", filter: false, width: 150, cellRenderer: (params) => (
        <div class="d-grid gap-2 col-6 mx-auto">
          <button type="submit" class="btn btn-outline-secondary btn-sm" ><AddLinkIcon /></button>
        </div>
      )
    },
    {
      field: "", headerName: "Limpar Fardos", filter: false, width: 150, cellRenderer: (params) => (
        <div class="d-grid gap-2 col-6 mx-auto">
          <button type="submit" class="btn btn-outline-danger btn-sm" >
            <DeleteForeverIcon /></button>
        </div>
      )
    }
  ];

  return (
    <>
      <div className="row">
        <div className="pt-2 col-10">
          <div class="form-check form-check-inline ">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault" />
            <label class="form-check-label text-light" for="inlineCheckbox1"
            checked={kgs} // estado do checkbox
            onChange={(e) => setKgs(e.target.checked)}>KGS</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault" 
            checked={tara} // estado do checkbox
            onChange={(e) => setTara(e.target.checked)}/>
            <label class="form-check-label text-light" for="inlineCheckbox2">Tara</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault" 
            checked={lote} // estado do checkbox
            onChange={(e) => setLote(e.target.checked)}/>
            <label class="form-check-label text-light" for="inlineCheckbox1">Lote</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault" 
            checked={separaSaiCode} // estado do checkbox
            onChange={(e) => separaSaiCode(e.target.checked)}/>
            <label class="form-check-label text-light" for="inlineCheckbox1">Separa SAI Code/Bale</label>
          </div>
        </div>
        <div className="pt-2 col-2">
          <select id="inputState" class="form-select transparent-input form-select-sm">
            <option selected>Packing List</option>
          </select>
        </div>
      </div>
      <div><hr class="divider bg-custom" /></div>
      <div className="ag-theme-quartz" style={{ height: 600 }}>
        <AgGridReact
          rowData={packingList}
          columnDefs={colDefs}
          rowSelection="unique"
        />
      </div>
      <div class="row g-1">
        <div class="col-md-1">
          <label for="emailfat" class="form-label hfx4">Fardos</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-1">
          <label for="emailfat" class="form-label hfx4">Gross Weigth</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-1">
          <label for="emailfat" class="form-label hfx4">Tara Cargo</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-1">
          <label for="emailfat" class="form-label hfx4">Net Weigth</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-1">
          <label for="emailfat" class="form-label hfx4">Average</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-2">
          <label for="emailfat" class="form-label hfx4">Fardos Packing</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-2">
          <label for="emailfat" class="form-label hfx4">Gross Packing</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-2">
          <label for="emailfat" class="form-label hfx4">Tare Packing</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
        <div class="col-md-1">
          <label for="emailfat" class="form-label hfx4">Net Packing</label>
          <input type="text" class="form-control transparent-input rounded form-control-sm" />
        </div>
      </div>
      <div><hr class="divider bg-custom" /></div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-2">
            <label for="emailfat" class="form-label hfx4">Qtd Digitos</label>
            <input type="number" class="form-control transparent-input rounded form-control-sm" value={qtdDigits} onChange={(e) => setQtdDigits(e.target.value)}>
            </input>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <div class="col-md-2">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"  
                checked={baleId} // estado do checkbox
                onChange={(e) => setBaleId(e.target.checked)}/>
                <label class="form-check-label text-light" >BaleID</label>
              </div>
            </div>
          </div>
        </div>
        <div><hr class="divider bg-custom" /></div>
        <button type="submit" class="btn btn-success"><FileDownloadIcon /> Packing List</button>
        <button type="submit" class="btn btn-success mx-2"><FileDownloadIcon /> Packing List (Unificado)</button>
        <button type="submit" class="btn btn-primary"><DoneOutlinedIcon /> Packing List Digital</button>
        <button type="submit" class="btn btn-success "><FileDownloadIcon /> Processar HVI</button>
      </div>
      <PLModal
        aloca={codAloca}
      />
    </>
  );
};

export default PackingList;

