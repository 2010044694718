import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DoneIcon from '@mui/icons-material/Done';
import Navbar from "../navbar/navbar";
import "../../style/style.css"
import RelatorioFModal from "./relatorio_fmodal";
import axios from 'axios';

const RelatorioF = () => {
    const navigate = useNavigate();
    const typeUser = parseInt(localStorage.getItem("typeUser"));
    const token = localStorage.getItem("token");
    const parsedURL = localStorage.getItem('urlParsed');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const osd = searchParams.get('idos');
    const [loading, setLoading] = useState(true);
    const [codOrder, setCodOrder] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [dadosCntr, setDadosCntr] = useState([]);
    const [nracond, setNracond] = useState(null);
    const [dados, setDados] = useState({
        "codos": "",
        "ie": "",
        "booking": "",
        "cliente": "",
        "terminal": "",
        "sta_envioPeso": "",
        "declaration_photo_report": "",
        "date_enable_report": ""
    });

    useEffect(() => {
        handleGetContainers();
        handleGetData();
        if (dados.declaration_photo_report == null ||dados.declaration_photo_report == ""){
            generateDeclarationIniti();
        };
        

      }, []);

    const handleGetContainers = async (cdos) => {
        try {
            const response = await axios.get(
                `${parsedURL}get/relatorio_fotografico/containers?codos=${osd}`,
                {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            if (response.status === 200) {
                toast.success('Dados Validados com sucesso! ', {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(response.data);
                setDadosCntr(response.data);
                setLoading(false)
            }
        } catch (error) {
            toast.error('Dados de Container Não encontrado', {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log(error)
        }
    }

    const handleGetData = async () => {
            console.log(token);
            try {
                
                const response = await axios.get(
                    `${parsedURL}get/relatorio_fotografico/consulta_data?codos=${osd}`,
                    {
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    }
                );
                if (response.status === 200) {

                    console.log(response.data);
                    setDados(response.data);
                    handleGetContainers(response.data.codos);
                }
            } catch (error) {
                toast.error('Dados Gerais Não encontrado', {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(error)
            }
        
    };

    const handleChange = (event) => {
    const { name, value } = event.target;
        setDados((prevDados) => ({
          ...prevDados, // mantenha o conteúdo atual
          [name]: value
        }));
      };


    function getDataAtual() {
        const hoje = new Date();
        const ano = hoje.getFullYear();
        const mes = String(hoje.getMonth() + 1).padStart(2, '0'); // Mês é base zero
        const dia = String(hoje.getDate()).padStart(2, '0');
        const date = `${ano}-${mes}-${dia}`;
        console.log(date);
        setDados((prevDados) => ({
            ...prevDados, // mantenha o conteúdo atual
            date_enable_report: date
          }));
      }

    const handleSetContr = async () => {
        if (erNumberValidation(nracond) == true){
            try {
              const response = await axios.post(
                `${parsedURL}post/cad_rel_photografic/set_container?codos=${osd}`,
                {
                    'nracond':`${nracond}`
                }
                ,{
                  headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                }
              );
              if (response.status === 200) {
                window.location.reload();
              }
            } catch (error) {
              console.log(error);
            };
        }else {
            toast.error('Container informado incorreto!', {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
 };

 const handleUpdateData = async () => {
        try {
          const response = await axios.put(
            `${parsedURL}put/cad_rel_photografic/update_data_os?codos=${osd}`,
            dados
            ,{
              headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            }
          );
          if (response.status === 200) {
            window.location.reload();
          }
        } catch (error) {
          console.log(error);
        };
    };



    const handleInputChange = (e) => {
        setNracond(e.target.value);
      };
      const fetchDownloadResume = () => {
        axios({
          method: 'GET',
          url: `${parsedURL}get/cad_rel_photografic/download_report?codos=${osd}`,
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
          .then(response => {
            console.log('Resposta recebida:', response);
            const contentDisposition = response.headers['content-disposition'];
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            let fileName = matches && matches[1] ? matches[1] : `Photo Report ${dados.ie}.pdf`;
    
            // Substituir '/' por '-'
            if (fileName) {
              fileName = fileName.replace(/\//g, '-');
            }
    
            // Criar um URL temporário para o blob de dados
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    
            // Criar um elemento de âncora para iniciar o download
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = fileName;
    
            // Simular um clique no link para iniciar o download
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
    
            // Liberar o URL temporário
            window.URL.revokeObjectURL(blobUrl);
          })
          .catch(error => {
    
          });
    
      };
    const generateDeclarationIniti = () => {

        setDados((prevDados) => ({
          ...prevDados, // mantenha o conteúdo atual
          declaration_photo_report: "We declare to those who might be interested, that the whole stuffing process was carried out within the normal standards and there was no problem of damage, either in the container or in the bales. Both were in perfect conditions.", // atualize/adicione a propriedade 'safra'
        }));
      };

    return (<>
        <Navbar></Navbar>

        <div className="mt-2 p-0">
            <div className="card col-md-12 mx-auto box-center">
                <legend className="hfx2">Relatorio Fotografico</legend>
                <div className="card-body">
                    <form>
                        <div class="row mb-3">
                            <label for="inputEmail3" class="col-sm-2 col-form-label hfx" >Instrução de embarque</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail3" readOnly="true" value={dados.ie} placeholder="Instrução de Embarque" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="inputEmail3" class="col-sm-2 col-form-label hfx">Booking</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail3" readOnly="true" value={dados.booking} placeholder="Booking" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="inputEmail3" class="col-sm-2 col-form-label hfx">Cliente</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail3" readOnly="true" value={dados.cliente} placeholder="Shipper" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="inputEmail3" class="col-sm-2 col-form-label hfx">Terminal</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail3" readOnly="true" value={dados.terminal} placeholder="Terminal de Estufagem" />
                            </div>
                        </div>
                        <div><hr class="divider" /></div>

                        <form class="row g-3">
                            <div className="col-md-4">
                                <label htmlFor="razaosocial" className="form-label3">Container</label>
                                <div class="form-control" rows="15" style={{ maxHeight: '370px', overflowY: 'auto' }}>
                                    <ul className="list-group">
                                        {dadosCntr.map(item => (
                                            <li key={item.codacond} className={`list-group-item ${item.dtRelFot !== null ? 'list-group-item-success' : ''} d-flex justify-content-between align-items-start`}>
                                                {item.nracond}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <label for="razaosocial" class="form-label3 ">Declaração</label>
                                <textarea class="form-control" value={dados.declaration_photo_report} onChange={handleChange} rows="15" name="declaration_photo_report"></textarea>
                            </div>
                            <div class="col-md-4 my-4">
                                <div class="input-group">
                                    <button type="button" class="btn btn-primary" onClick={handleSetContr}>+</button>
                                    <input type="text" value={nracond} onChange={handleInputChange} class="form-control" placeholder="Adicionar Container" aria-label="Input group example" aria-describedby="btnGroupAddon" />
                                </div>
                            </div>
                            
                            <div><hr class="divider" /></div>
                        </form>
                            <div class="col-md-4 my-2" >
                                <div class=" input-group">
                                    <input type="date" class="form-control mx-2" readOnly={true} value={dados.date_enable_report} ></input>
                                    <button type="button" class="input-group-text btn-primary btn" onClick={getDataAtual} name="date_enable_report" >< DoneIcon/></button>
                                </div>
                            </div>
                        <button type="button" class="btn btn-warning mx-2" data-bs-toggle="modal" data-bs-target="#modal6" readOnly={loading} onClick={() => setModalOpen(true)}>Importar Imagens</button>
                        <button type="button" class="btn btn-danger mx-2" readOnly={loading} onClick={fetchDownloadResume} >Gerar PDF</button>
                        <button type="button" class="btn btn-success mx-2" readOnly={loading}  onClick={handleUpdateData} >Salvar</button>

                    </form>
                </div>
            </div>
        </div>
        <RelatorioFModal 
        codOrder={dados.codos}
        isOpen={modalOpen} />
        <ToastContainer stacked
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </>
    );
};

export default RelatorioF;

function erNumberValidation(cntrNbr) {
    const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const CODES = [
      10, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      32, 34, 35, 36, 37, 38
    ];
  
    if (cntrNbr.startsWith('XXXX')) {
      return true;
    }
  
    // Remove espaços e hífens do número do contêiner
    cntrNbr = cntrNbr.replace(/\s/g, '').replace(/-/g, '');
    let intSoma = 0;
    let intIndice = 1;
  
    for (let i = 0; i < 11; i++) {
      let intCodeNumber;
      if (i < 4) {
        intCodeNumber = CODES[ALPHA.indexOf(cntrNbr[i])];
      } else {
        intCodeNumber = parseInt(cntrNbr[i]) || 0;
      }
  
      if (i < 10) {
        intSoma += intCodeNumber * intIndice;
        intIndice *= 2;
      }
    }
  
    intIndice = intSoma % 11;
    if (intIndice === 10) {
      intIndice = 0;
    }
    console.log(`${intIndice} = ${parseInt(cntrNbr[10])}`)
    return intIndice === parseInt(cntrNbr[10]);
  }