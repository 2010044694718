import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";


const LoteProc2 = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
  }, []);

  const columns = [
    {
      field: "codlote",
      headerName: "Cod",
      width: 90
    },
    { field: "lote", headerName: "Lote", width: 150 },
    { field: "tara", headerName: "Tara", width: 150 }
  ];

  return (
    <>
      <form class="row">
        <div className="pt-4 rounded ">
          <label for="inputCity" class="form-label2">Lotes Alocados</label>
          <div><hr class="divider bg-custom " /></div>
          <div className="ag-theme-quartz"   style={{ height: 400, width: '100%' }}>
            <AgGridReact 
              rowData={data}
              columnDefs={columns}
              
              rowSelection="single"
            />
          </div>
        </div>
      </form>

    </>
  );
};

export default LoteProc2;