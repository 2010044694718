import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";


const CadLista2 = () => {
    const [data, setData] = useState([]);
  
   
    const getConsultaOS = async () => {
      try {
        const response = await axios.get("https://www.balldontlie.io/api/v1/teams");
        setData(response.data.data.map(row => ({ ...row, selected: false })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      getConsultaOS();
    }, []);
  
    const handleCheckboxChange = (id) => {
      setData((prevData) => {
        const updatedData = prevData.map((row) =>
          row.id === id ? { ...row, selected: !row.selected } : row
        );
        return updatedData;
      });
    };
  
    const columns = [
      {
        field: "id",
        headerName: "ID",
        width: 90,
        renderCell: (params) => (
          <Checkbox
            checked={params.row.selected}
            onChange={() => handleCheckboxChange(params.row.id)}
          />
        ),
      },
      { field: "city", headerName: "City", width: 150 },
      { field: "abbreviation", headerName: "Abbreviation", width: 150 },
      { field: "conference", headerName: "Conference", width: 150 },
      { field: "division", headerName: "Division", width: 150 },
    ];
  
    const rows = data.map((row) => ({
      id: row.id,
      abbreviation: row.abbreviation,
      city: row.city,
      conference: row.conference,
      division: row.division,
    }));
  
    console.log(data);

  return (
        <>
        <div><hr class="divider bg-custom "/></div>
  	<div  className="my-3 transparent-input" style={{ height: 700, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
        </>
  );
};

export default CadLista2;