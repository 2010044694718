import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../assets/logoag.png";
import "../../style/style.css"




const Navbar = () => {
    const navigate = useNavigate();
    const [admin, setAdmin] = useState(false);
    const [user, setUser] = useState(false);
    const [client, setClient] = useState(false);
    const [fat, setFat] = useState(false);

    document.addEventListener("DOMContentLoaded", function () {

        /////// Prevent closing from click inside dropdown
        document.querySelectorAll('.dropdown-menu').forEach(function (element) {
            element.addEventListener('click', function (e) {
                e.stopPropagation();
            });
        })

        // make it as accordion for smaller screens
        if (window.innerWidth < 992) {

            // close all inner dropdowns when parent is closed
            document.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown) {
                everydropdown.addEventListener('hidden.bs.dropdown', function () {
                    // after dropdown is hidden, then find all submenus
                    this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
                        // hide every submenu as well
                        everysubmenu.style.display = 'none';
                    });
                })
            });

            document.querySelectorAll('.dropdown-menu a').forEach(function (element) {
                element.addEventListener('click', function (e) {

                    let nextEl = this.nextElementSibling;
                    if (nextEl && nextEl.classList.contains('submenu')) {
                        // prevent opening link if link needs to open dropdown
                        e.preventDefault();
                        console.log(nextEl);
                        if (nextEl.style.display == 'block') {
                            nextEl.style.display = 'none';
                        } else {
                            nextEl.style.display = 'block';
                        }

                    }
                });
            })
        }
        // end if innerWidth

    });

    // DOMContentLoaded  end
    const HandleExitSystem = async (event) => {
        event.preventDefault();
        localStorage.removeItem('token');
        localStorage.removeItem('typeUser');
        localStorage.clear();
        navigate('/login', { replace: true });
        window.location.reload();
        //location.reload();
    }

    useEffect(() => {
        const typeUser = parseInt(localStorage.getItem("typeUser"));

        if (!isNaN(typeUser)) {
            if (typeUser === 120) {
                setAdmin(true);
                setUser(false);
                setClient(false);
                setFat(false);
            } else if (typeUser === 110) {
                setAdmin(false);
                setUser(false);
                setClient(false);
                setFat(true);
            } else if (typeUser === 100) {
                setAdmin(false);
                setUser(true);
                setClient(false);
                setFat(false);
            } else if (typeUser === 90) {
                setAdmin(false);
                setUser(false);
                setClient(true);
                setFat(false);
            } else {
                // Caso não corresponda a nenhum tipo específico
                setAdmin(false);
                setUser(false);
                setClient(false);
                setFat(false);
            }
        }
    }, []);

    return (
        <nav class="navbar navbar-expand-lg navbar-dark bg-custom -w">
            <div class="container-fluid">
                <a class="navbar-brand" href="https://agsurveyors.com"><img src="https://agsurveyors.com/wp-content/uploads/2024/02/Rebranding_AG_Surveyors_REV4-01-1.png" alt="AG Surveyors" width="50" height="44" /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="main_nav">
                    <ul class="navbar-nav">
                        <Link to="/home"><button type="button" class="btn btn-sm btn-primary mx-1">Home</button></Link>
                        {(admin || user) && (<li class="nav-item dropdown">
                            <div class="btn-group">
                                <button type="button" class="btn btn-sm bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Cadastro</button>
                                <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                    <li><Link class="dropdown-item" to="/cadastro/entidade">Cadastro de Entidade</Link></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><Link class="dropdown-item" to="/cadastro/os">Cadastro OS</Link></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><Link class="dropdown-item" to="/cadastro/nf">Cadastrar Nota Fiscal</Link></li>
                                    <li><Link class="dropdown-item" to="/cadastro/lote">Cadastrar Lotes</Link></li>
                                    <li><Link class="dropdown-item" to="/cadastro/pesagem">Cadastrar Pesagem</Link></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><Link class="dropdown-item" to="/cadastro/relatorio_fotografico">Relátorio Fotográfico</Link></li>
                                </ul>
                            </div>
                        </li>)}
                        {(admin || user) && (<li class="nav-item dropdown">
                            <div class="btn-group">
                                <button type="button" class="btn btn-sm bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Consulta</button>
                                <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                    <li><a class="dropdown-item" href="#">Consulta de Entidade</a></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><Link class="dropdown-item" to="/consultar_os">Consultar OS</Link></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><Link class="dropdown-item" to="/consulta/nf">Consulta Nota Fiscal</Link></li>
                                    <li><Link class="dropdown-item" to="/consulta/lote">Consulta Lote</Link></li>
                                    <li><Link class="dropdown-item" to="/consulta/pesagem">Consulta Pesagem</Link></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li><a class="dropdown-item" href="/consulta">Consulta de Certificados</a></li>
                                </ul>
                            </div>
                        </li>)}
                        {admin && (<li class="nav-item dropdown">
                            <div class="btn-group">
                                <button type="button" class="btn btn-sm bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Faturamento</button>
                                <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                                    <li><Link class="dropdown-item" to="/faturamento/calculo">Faturamento</Link></li>
                                </ul>
                            </div>
                        </li>)}
                        {(admin || user || client) && (<li class="nav-item dropdown">
                            <div class="btn-group">
                                <button type="button" class="btn btn-sm bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Integração</button>
                                <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                    {client && (<li><Link class="dropdown-item" to="/integracao/integracao_romaneio">Integração de Romaneio</Link></li>)}
                                    {user || admin && (<li><a class="dropdown-item" href="#">Romaneio &raquo; </a>
                                        <ul class="submenu dropdown-menu">
                                            <li><a class="dropdown-item" href="#">Cadastro</a></li>
                                            <li><a class="dropdown-item" href="/integracao/consulta_romaneio">Consulta</a></li>
                                        </ul>
                                    </li>)}
                                </ul>
                            </div>
                        </li>)}
                        {(admin || user) && (<li class="nav-item dropdown">
                            <div class="btn-group">
                                <button type="button" class="btn btn-sm bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Funções</button>
                                <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                    <li><Link class="dropdown-item" to="/funcoes/transferir_container">Transferir Container</Link></li>
                                    <li><Link class="dropdown-item" to="/funcoes/combinar_excel">Combine Excel</Link></li>
                                    <li><Link class="dropdown-item" to="/funcoes/gerar_txt">Gerar TXT</Link></li>
                                </ul>
                            </div>
                        </li>)}
                    </ul>

                    <ul class="navbar-nav ms-auto">
                        {admin && (<div class="btn-group">
                            <button type="button" class="btn btn-sm bg-custom dropdown-toggle mx-2 me-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Manutenção</button>
                            <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-ls-start">
                                <li><a class="dropdown-item" href="#">Cadastro de usuário</a></li>
                                <li><a class="dropdown-item" href="#">Consulta de usuário</a></li>
                                <li><hr class="dropdown-divider " /></li>
                                <li><a class="dropdown-item" >Cadastro de Permissão</a></li>
                                <li><a class="dropdown-item" >Gerenciar Permissão</a></li>
                            </ul>
                        </div>)}
                        <button type="button" class="btn btn-sm btn-danger me-1 mx-1" onClick={HandleExitSystem} >Sair</button>
                    </ul>
                </div>
            </div>
        </nav>

    );
};

export default Navbar;