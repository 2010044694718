import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import {CancelOutlined, CheckCircleOutline, FileDownload, PlayArrow, Search } from "@mui/icons-material";
import Tabelanf1 from "./tabela1nf";
import Tabelanf2 from "./tabela2nf";

const ConsultNf = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
        <form className="box-center">
        <div className="hfx">Consulta Nota Fiscal</div>
           <div className="row g-3 my-2">
           <div class="row align-items-start">
                <div class="col-md-2">
                    <label for="razaosocial" class="form-label">Nota Fiscal</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label">Emissor</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-2">
                    <label for="razaosocial" class="form-label">Lote</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label">Terminal</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4 text-center">
                <label for="razaosocial" class="form-label">Entre Datas de Emissão</label>
                <div class="input-group">
                    <input type="date" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon"/>
                    <div class="input-group-text transparent-input" id="btnGroupAddon">a</div>
                    <input type="date" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon"/>
                </div>
                </div>
                <div class="col-md-8">
                    <label for="razaosocial" class="form-label">Produtor</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-12 pt-4">
                <div class=" text-end">
                    <div class="row align-items-start hfx">
                        <div class="col">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                            <label class="form-check-label" for="inlineRadio1">Integrada</label>
                            </div>
                        </div>
                        <div class="col-4">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                            <label class="form-check-label" for="inlineRadio2">Somente fardos disponíveis - Saldo</label>
                            </div>
                        </div>
                        <div class="col-2">
                        <button type="button" class="btn btn-primary"><Search></Search> Pesquisar</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div><hr class="divider bg-custom "/></div>
                <div class="col">
                <div class="col-12 text-end">
                        <button type="button" class="btn btn-success"><FileDownload></FileDownload> Exportar Pesquisa</button>
                        </div>
                </div>
                <Tabelanf1></Tabelanf1>
                </div> 
                <div><hr class="divider bg-custom "/></div>
                <div class="container text-center">
                    <div class="row align-items-start">
                        <div class="col">
                        <Tabelanf2></Tabelanf2>
                        </div>
                        <div class="col align-self-center">
                        <button type="button" class="btn btn-primary btn-lg"><PlayArrow></PlayArrow> Exibir Utilização dos Fardos</button>
                        </div>
                    </div>
                    </div>
                        <div class="row align-items-start">
                            <div class="col">
                            <button type="button" class="btn btn-success"><FileDownload></FileDownload> Exportar Pesquisa</button>
                            </div>
                            <div class="col text-end">
                            <button type="button" class="btn btn-danger mx-2"><CancelOutlined></CancelOutlined> Cancelar</button>
                            <button type="button" class="btn btn-success"><CheckCircleOutline></CheckCircleOutline> Confirmar</button>
                            </div>
                        </div>
            </div> 
        </form>
        </>
  );
};

export default ConsultNf;             
            