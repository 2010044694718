import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import background from "../../assets/cottonbackground-1.jpg"
import logo from "../../assets/logoag.png"
import logoAgs from "../../assets/AGSLOGO.png"
import "../../style/style.css"
import axios from 'axios';
import { Chat } from "@mui/icons-material";

const Login = () => {
    const [cpf_user, setCpf_user] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const parsedURL = localStorage.getItem('urlParsed');

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
            const resposta = await axios.post(
                `${parsedURL}post/login`,
                {
                    cpf_user: cpf_user,
                    password: password
                },
                { headers }
            );
            const data = resposta.data.user_info;
            const token = resposta.data.token;
            console.log(data);
            console.log(token);
            if (data.statusUser === 'S') {
                localStorage.setItem('token', token);
                localStorage.setItem('typeUser', data.nivel);
                localStorage.setItem('codoffice', data.codoffice);
                navigate('/home');
            } else {
                toast.error('Usuario Bloqueado', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            toast.error('Usuario ou senha incorreto!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin(event);
        }
    };

    return (
        <body class="my-login-page">
            <section class="h-100">
                <div class="container h-100">
                    <div class="row justify-content-md-center h-100">
                        <div class="card-wrapper">
                            <div class="brand">
                                <img src={logoAgs} />
                            </div>
                            <div class="card fat">
                                <div class="card-body">
                                    <h5 class="card-title text-center">Login</h5>
                                    <div onKeyDown={handleKeyDown}>
                                        <form method="POST" class="my-login-validation" novalidate="">
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput" placeholder="xxx.xxx.xxx-xx" onChange={(e) => setCpf_user(e.target.value)}
                                                    value={cpf_user} />
                                                <label for="floatingInput">CPF</label>
                                            </div>
                                            <div class="form-floating">
                                                <input type="password" class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                                                    value={password} />
                                                <label for="floatingPassword">Senha</label>
                                            </div>
                                            <div class="form-group text-center mb-3">
                                                <Link to="/home"><button type="button" class="btn btn-outline-success btnn " onClick={handleLogin}>Entrar</button></Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="footer">
                                Copyright &copy; 2023 &mdash; AG Surveyors
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            </section>
        </body>
    );
};

export default Login;

