import { Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Login from '../pages/login/login';
import Home from '../pages/home/home';
import Cadastro_Entidade from '../pages/entidades/cadastro_entidade';
import OS_Cadastro1 from '../pages/ordem_servico/cadastro_os1';
import CadastroCertificado from '../pages/certificado/cadastro_certificado';
import RelatorioF from '../pages/relatorio_fotografico/relatorio_f';
import GerarTXT from '../pages/funçoes/gerar_txt';
import TransferirContainer from '../pages/funçoes/transferir_container';
import CombinexExcel from '../pages/funçoes/combinex_excel';
import ConsultaOS from '../pages/ordem_servico/consulta_os';
import LoteV from '../pages/ordem_servico/lote_v';
import StatusOS from '../pages/ordem_servico/status_os';
import AddArquivo from '../pages/funçoes/add_arquivo';
import IntegracaoNF from '../pages/nota_fiscal/nf_integracao';
import CadastrarNF from '../pages/nota_fiscal/nf_cadastro';
import Faturamento from '../pages/faturamento/faturamento';
import ConsultNf from '../pages/nota_fiscal/nf_consulta';
import Pesagem from '../pages/nota_fiscal/pesagem';
import PesagemConsult from '../pages/nota_fiscal/pesagem_consult';
import LoteConsult from '../pages/ordem_servico/lote_consult';
import LoteEdit from '../pages/ordem_servico/lote_edit';
import LoteCad from '../pages/ordem_servico/lote_cad';
import PlanilhaPeso from '../pages/planilha_peso/planilha_peso';


const Private = ({ Item }) => {
    const token = localStorage.getItem('token');
    console.log(token)
    if (token === null) {
      return <Navigate to="/login" />;
    }
    return <Item />;
  };
  
  const RouteApp = () => {
    return (
      <BrowserRouter>
        <Fragment>
          <Routes>
          <Route path="/login" element={<Login />} />
            <Route path="*" element={<Private Item={StatusOS} />} />
            <Route path="/faturamento/calculo" element={<Private Item={Faturamento} />} />
            <Route path="/integracao/consulta_romaneio" element={<Private Item={IntegracaoNF} />} />
            <Route path="/home" element={<Private Item={StatusOS} />} />
            <Route path="/funcoes/gerar_txt" element={<Private Item={GerarTXT} />} />
            <Route path="/lote_v" element={<Private Item={LoteV} />} />
            <Route path="/funcoes/transferir_container" element={<Private Item={TransferirContainer} />} />
            <Route path="/funcoes/combinar_excel" element={<Private Item={CombinexExcel} />} />
            <Route path="/cadastro/entidade" element={<Private Item={Cadastro_Entidade} />} />
            <Route path="/cadastro/os" element={<Private Item={OS_Cadastro1} />} />
            <Route path="/cadastro/nf" element={<Private Item={CadastrarNF} />} />
            <Route path="/cadastro/relatorio_fotografico" element={<Private Item={RelatorioF} />} />
            <Route path="/cadastro/certificado" element={<Private Item={CadastroCertificado} />} />
            <Route path="/consulta/os" element={<Private Item={ConsultaOS} />} />
            <Route path="/integracao/integracao_romaneio" element={<Private Item={AddArquivo} />} />
            <Route path="/consulta/lote" element={<Private Item={LoteConsult} />} />
            <Route path="/editar" element={<Private Item={LoteEdit} />} />
            <Route path="/cadastro/lote" element={<Private Item={LoteCad} />} />
            <Route path="/cadastro/pesagem" element={<Private Item={Pesagem} />} />
            <Route path="/consulta/pesagem" element={<Private Item={PesagemConsult} />} />
            <Route path="/consulta/nf" element={<Private Item={ConsultNf} />} />
            <Route path="/cadastro/planilha_peso" element={<Private Item={PlanilhaPeso} />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    );
  };
  
  export default RouteApp;