import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import Pesagem from "../nota_fiscal/pesagem";
import PesagemSaida from "./pesagem_saida";
import ComparativaPeso from "./comparativo_peso";
import VizualizarFinal from "./vizualizar_final";
import PackingList from "./packing_list";
import FardosRepetidosProcesso from "./fardos_repetidos_processo";
import FardosRepetidosIE from "./fardos_repetidos_ie";
import FardosRepetidosGeral from "./fardos_repetidos_geral";
import FardosNpertencentes from "./fardos_npertencentes";
import axios from "axios";

const PlanilhaPeso = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const osd = searchParams.get('idos');
  const token = localStorage.getItem("token");
  const [data, setData] = useState({
    "os": "",
    "ie": "",
    "sta": "",
    "exportador": "",
    "sta_envioPeso": "",
    "qtdFull": ""
  });
  const parsedURL = localStorage.getItem('urlParsed');// Estado para a opção selecionada
  const typeUsr = localStorage.getItem('typeUser');
  const [typesCalculates, setTypesCalculates] = useState();
  const [selectTypeCalculate, setSelectTypeCalculate] = useState('');

  useEffect(() => {
    handleGetOrde();
    handleGetTypesCalculates();
  }, []);

  const fetchExecuteCalculo = () => {
    setLoading(true);
    toast.info('Realizando o Calculo do processo', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    const token = localStorage.getItem('token');

    axios({
      method: 'POST',
      url: `${parsedURL}${selectTypeCalculate}?codos=${osd}`,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(async response => {
        console.log('Resposta recebida:', response);
      if (response.status === 200) {
          const idCelery = response.data;
          let resp;

          while (true) { // Loop infinito
            resp = await axios.get(
              `${parsedURL}get/status/${selectTypeCalculate}/${idCelery}`,
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              }
            );
    
        if (resp.data !== null) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          // Verifica se a resposta contém um blob (arquivo)
          if (resp.data instanceof Blob) {
            // Extrair o nome do arquivo do cabeçalho content-disposition
            const contentDisposition = resp.headers['content-disposition'];
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            let fileName = matches && matches[1] ? matches[1] : `Romaneio_Unificado_${data.ie}.xlsx`;

            // Substituir '/' por '-'
            fileName = fileName.replace(/\//g, '-');
            
            // Criar um URL temporário para o blob de dados
            const blobUrl = window.URL.createObjectURL(new Blob([resp.data]));
            
            // Criar um elemento de âncora para iniciar o download
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = fileName;
            
            // Simular um clique no link para iniciar o download
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setLoading(false);
            // Liberar o URL temporário
            window.URL.revokeObjectURL(blobUrl);
            toast.success('Romaneio Gerado com Sucesso!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            setLoading(false);
            // Aqui você pode lidar com o caso em que a resposta não é um blob (arquivo)
            toast.success('Calculo Realizado com sucesso!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          break;
        }
        }
      }
    }
    )
    .catch(error => {
        setLoading(false);
        toast.error('Ocorreu um erro ao Calcular os Pesos', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleGetOrde = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/planilha_peso/dados_os?codos=${osd}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setData(response.data);

      }
    } catch (error) {
    }
  }

  const handleGetTypesCalculates = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/planilha_peso/buscar_calculos`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setTypesCalculates(response.data);
        console.lote(response.data);
      }
    } catch (error) {
    }
  }

  return (
    <>
      <Navbar></Navbar>
      <div className="box-center">
        <legend className="hfx2">Planilha de Peso</legend>
        <div class="row g-1">
          <div class="col-md-2">
            <label for="emailfat" class="form-label hfx4">Ordem de serviço</label>
            <input type="text" readOnly="true" value={data.os} class="form-control transparent-input rounded form-control-sm" />
          </div>
          <div class="col-md-2">
            <label for="emailfat" class="form-label hfx4">I.E.</label>
            <input type="text" readOnly="true" value={data.ie} class="form-control transparent-input rounded form-control-sm" />
          </div>
          <div class="col-md-2">
            <label for="emailfat" class="form-label hfx4">Status</label>
            <input type="text" readOnly="true" value={data.sta} class="form-control transparent-input rounded form-control-sm" />
          </div>
          <div class="col-md-2">
            <label for="emailfat" class="form-label hfx4">Exportador</label>
            <input type="text" readOnly="true" value={data.exportador} class="form-control transparent-input rounded form-control-sm" />
          </div>
          <div class="col-md-2">
            <label for="emailfat" class="form-label hfx4">Data envio do Peso</label>
            <input type="text" readOnly="true" value={data.sta_envioPeso} class="form-control transparent-input rounded form-control-sm" />
          </div>
          <div class="col-md-2">
            <label for="emailfat" class="form-label hfx4">Qtd Fardos Instruídos</label>
            <input type="text" readOnly="true" value={data.qtdFull} class="form-control transparent-input rounded form-control-sm" />
          </div>
          <div class="col-2">
            <div class="form-check mt-2">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
              <label class="form-check-label hfx4" for="exampleRadios1">Reajustar Valores</label>
            </div>
          </div>
          <div class="col-8">
            <div class="form-check mt-2">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
              <label class="form-check-label hfx4" for="exampleRadios1">Não Reajustar Valores</label>
            </div>
          </div>
          <div class="col-2">
            <div class="col-md-12">
              <label for="emailfat" class="form-label hfx4">Tipo de peso</label>
              <select
                className="form-select transparent-input form-select-sm"
                value={selectTypeCalculate}
                onChange={(e) => {
                  console.log("Novo valor selecionado:", e.target.value);
                  setSelectTypeCalculate(e.target.value);
                }}
                >
                <option>Selecione um Cliente</option>
                {typesCalculates && typesCalculates.map(option => (
                  <option key={option.id_tipo} value={option.end_point}>{option.fantasia}</option>
                ))}
              </select>
            </div>


            <div class="col-md-12">
              <button
                type="button"
                className="my-2 btn btn-success"
                onClick={() => fetchExecuteCalculo()}
              >
                Calcular peso automatico
              </button>
              {loading && 
        <div class="d-flex align-items-center">
        <strong className='text-light' role="status">Loading...</strong>
        <div class="spinner-border ms-auto text-light" aria-hidden="true"></div>
      </div>}
            </div>
          </div>
        </div>
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active form-label" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">Pesagem Container / Truck (Saída)</button>
            <button class="nav-link form-label" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Comparativo de Pesos</button>
            <button class="nav-link form-label" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3" aria-selected="false">Vizualizar Planilha Final</button>
            <button class="nav-link form-label" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4" aria-selected="false">Packing List</button>
            <button class="nav-link form-label" id="nav-5-tab" data-bs-toggle="tab" data-bs-target="#nav-5" type="button" role="tab" aria-controls="nav-5" aria-selected="true">Fardos Repetidos no Processo</button>
            <button class="nav-link form-label" id="nav-6-tab" data-bs-toggle="tab" data-bs-target="#nav-6" type="button" role="tab" aria-controls="nav-6" aria-selected="false">Fardos Repetidos na IE</button>
            <button class="nav-link form-label" id="nav-7-tab" data-bs-toggle="tab" data-bs-target="#nav-7" type="button" role="tab" aria-controls="nav-7" aria-selected="false">Fardos Repetidos Geral</button>
            <button class="nav-link form-label" id="nav-8-tab" data-bs-toggle="tab" data-bs-target="#nav-8" type="button" role="tab" aria-controls="nav-8" aria-selected="false">Fardos não pertencente a IE / Lote</button>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active " id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
            <PesagemSaida></PesagemSaida>
          </div>
          <div class="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab" tabindex="0">
            <ComparativaPeso></ComparativaPeso>
          </div>
          <div class="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab" tabindex="0">
            <VizualizarFinal></VizualizarFinal>
          </div>
          <div class="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab" tabindex="0">
            <PackingList></PackingList>
          </div>
          <div class="tab-pane fade" id="nav-5" role="tabpanel" aria-labelledby="nav-5-tab" tabindex="0">
            <FardosRepetidosProcesso></FardosRepetidosProcesso>
          </div>
          <div class="tab-pane fade" id="nav-6" role="tabpanel" aria-labelledby="nav-6-tab" tabindex="0">
            <FardosRepetidosIE></FardosRepetidosIE>
          </div>
          <div class="tab-pane fade" id="nav-7" role="tabpanel" aria-labelledby="nav-7-tab" tabindex="0">
            <FardosRepetidosGeral></FardosRepetidosGeral>
          </div>
          <div class="tab-pane fade" id="nav-8" role="tabpanel" aria-labelledby="nav-8-tab" tabindex="0">
            <FardosNpertencentes></FardosNpertencentes>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>


  );
};

export default PlanilhaPeso;