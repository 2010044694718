import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import PrintIcon from '@mui/icons-material/Print';

const LoteV = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
        <div className="box-center">
        <form class="row g-3 py-2">
              <div class="col-md-3 ">
                    <label for="inputCity" class="form-label">Container</label>
                    <input type="text" class="form-control transparent-input" id="inputCity"/>
                    </div>
                    <div class="col-md-3">
                    <label for="telefone" class="form-label ">Nota</label>
                    <input type="text" class="form-control transparent-input" id="telefone" />
                    </div>
                    <div class="col-md-3">
                    <label for="inputCity" class="form-label">Lote</label>
                    <input type="text" class="form-control transparent-input" id="inputCity"/>
                    </div>
                    <div class="col-md-3">
                    <label for="inputCity" class="form-label">Quantidade</label>
                    <input type="text" class="form-control transparent-input" id="inputCity"/>
                    </div>
                    
                    
                    <div><hr class="divider bg-custom "/></div>
                    <div class="col-12">
                    <button type="submit" class="btn btn-primary">Salvar</button>
                    <button type="submit" class="btn mx-2 btn-secondary">Imprimir<PrintIcon className="mx-1"/></button>
                    </div>
                    </form>
                    </div>
        </>
  );
};

export default LoteV;