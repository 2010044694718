import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";


const IntegraçãoOS = ({onbuttonSave}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
  }, []);


  const columns = [
    { field: "nota", headerName: "Nota", width: 150 },
    { field: "lote", headerName: "Lote", width: 150 },
    { field: "qtdFardos", headerName: "Quantidade de Fardos", width: 250 }
  ];
  return (
    <>
      <form class="row g-3 py-2">
        <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            rowSelection="unique"
          />
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-primary" onClick={onbuttonSave} >Salvar</button>
        </div>
      </form>

    </>
  );
};

export default IntegraçãoOS;