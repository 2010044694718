import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Save, Search } from "@mui/icons-material";

const LoteEdit = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar/>
        <form className="box-center container">
            <legend class="hfx">Editar Lote</legend>
        <form class="row g-3 container">
                <div class="col-md-6">
                    <label for="razaosocial" class="form-label ">Lote</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Cód</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div><hr class="divider bg-custom "/></div>
                <div class="col-md-12">
                <label for="inputEmail3" class="col-sm-2 form-label">Cliente</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2"><Search></Search></button>
            </div>
            </div>
            <div class="col-md-12">
                <label for="inputEmail3" class="col-sm-2 form-label">Produtor</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2"><Search></Search></button>
            </div>
            </div>
            <div><hr class="divider bg-custom "/></div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Contrato</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Safra</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Tara</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Tipo de Fardo</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                </form><div><hr class="divider bg-custom "/></div>
                <button type="button" class="btn btn-warning"><Save></Save> Salvar</button>
        </form>
        </>
  );
};

export default LoteEdit;