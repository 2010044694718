import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../style/style.css";


const PesagemModal2 = () => {
  const [dataLotesInstruidos, setDataLotesInstruidos] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const osd = searchParams.get('idos');
  const [fardosUtilizados, setFardosUilizados] = useState();
  const [grossWeight, setGrossWeight] = useState();
  

  useEffect(() => {
    handleGetLotesHasNf();
  }, []);



  const handleGetLotesHasNf = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/planilha_peso/lotes_as_nf?codos=${osd}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setDataLotesInstruidos(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  }


  const handleCheckboxChange = (index) => {
    setDataLotesInstruidos((prevData) => {
      const updatedData = prevData.map((row) =>
        row.index === index ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const dfLotesInstruidos = [
    { field: "index", headerName: "", headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
    { field: "lote", headerName: "Lote", filter: true, valueFormatter: (params) => params.value || "",width: 120 },
    { field: "nf", headerName: "NF", filter: true,valueFormatter: (params) => params.value || "", width: 120 },
    { field: "fardos", headerName: "Fardos", filter: true, width: 120, cellStyle: { 'background-color': 'blueviolet' }, valueFormatter: (params) => params.value || "0" },
    { field: "disponiveis", headerName: "Disponiveis", filter: true, width: 120, cellStyle: { 'background-color': 'fuchsia' }, valueFormatter: (params) => params.value || "0"},
    { field: "Instruidos", headerName: "Instruidos", filter: true, width: 120, cellStyle: { 'background-color': 'yellow' }, valueFormatter: (params) => params.value || "0" },
    { field: "alocados", headerName: "Alocados", filter: true, width: 120, cellStyle: { 'background-color': 'aqua' }, valueFormatter: (params) => params.value || "0"},
    { field: "faltantes", headerName: "Faltantes", filter: true, width: 120, cellStyle: { 'background-color': 'darkseagreen' }, valueFormatter: (params) => params.value || "0"},
    { field: "produtor", headerName: "Produtor", filter: true, valueFormatter: (params) => params.value || "N/A", width: 120 },
    { field: "terminal", headerName: "Terminal", filter: true, valueFormatter: (params) => params.value || "N/A", width: 120 },
    { field: "dtemissao", headerName: "Data de Emissão", filter: true, valueFormatter: (params) => params.value || "00/00/0000", width: 120 },
    { field: "placa_caminhao", headerName: "Placa do Veiculo", filter: true, valueFormatter: (params) => params.value || "XXX0X00", width: 120 },
  ];

  return (
    <>
      <div className="ag-theme-quartz" style={{ height: 300 }}>
        <AgGridReact
          rowData={dataLotesInstruidos}
          columnDefs={dfLotesInstruidos}
          rowSelection="unique"
          onSelectionChanged={() => console.log('Row Selected!')}
        />
      </div>
      <div><hr class="divider bg-custom" /></div>
      {/*<button type="submit" class="btn btn-primary" onClick={handleAddRow}>Adicionar Linha</button>*/}

    </>
  );
};

export default PesagemModal2;
