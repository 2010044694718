import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const CombinexExcel = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
        <div className="container mt-2 p-0">
        <div className="card col-md-7 mx-auto">
          <div className="card-body">
          <form>
            <h4 className="text-center">Combinex Excel's</h4>
            <div class="mb-3">
                <label for="inputEmail3" class="col-sm-2 col-form-label">Aquivo Base</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Procurar</button>
            </div>
            </div>
            <div class="mb-3">
                <label for="inputEmail3" class="col-sm-2 col-form-label">Destino List</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Procurar</button>
            </div>
            </div>
            <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ height: '10px' }}>
            <div className="progress-bar" style={{ width: '25%' }}></div>
            </div>
                <div className="text-center my-3">
            <button type="submit" class="btn btn-primary">Combinar</button>
            </div>
            </form>
          </div>
        </div>
      </div>
        </>
  );
};

export default CombinexExcel;