import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import CadLista1 from "./lote_cadlista1";
import CadLista2 from "./lote_cadlista2";
import { Add, Remove } from "@mui/icons-material";

const LoteCad = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar/>
        <div class="box-center container">
            <legend class="hfx">Cadastrar Lote</legend>
                    <div class="row align-items-start">
                        <div class="col-5">
                        <form class="row">
                        <div class="mb-3">
                <label for="inputEmail3" class="form-label ">Cliente</label>
                <div class="input-group ">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Procurar</button>
            </div>
            </div>
            <div class="mb-3">
                <label for="inputEmail3" class="form-label">Produtor</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Procurar</button>
            </div>
            </div>
                    <div class="col-md-6">
                    <label for="inputCity" class="form-label">Safra</label>
                    <input type="text transparent-input" class="form-control" id="inputCity"/>
                    </div>
                    <div class="col-md-6">
                    <label for="inputCity" class="form-label">Instrução</label>
                    <input type="text transparent-input" class="form-control" id="inputCity"/>
                    </div>
                    <div class="col-md-6">
                    <label for="inputCity" class="form-label">Tipo do Fardo</label>
                    <input type="text" class="form-control" id="inputCity"/>
                    </div>
                    </form>
                    <CadLista1/>
                            <div class="col-12">
                            <button type="submit" class="btn btn-success"><Add></Add> Adicionar</button>
                            <button type="submit" class="btn mx-2 btn-danger"><Remove></Remove> Remover</button>
                            </div>
                        </div>
                        <div class="col-1">
                        <div class="form-check col-md-2 my-5">
                        <input class="form-check-input" type="checkbox" id="gridCheck"/>
                        <label class="hfx" for="gridCheck">
                            Copiar
                        </label>
                        </div>
                        </div>
                        <div class="col-6">
                            <CadLista2/>
                </div>
        </div>
        </div>

        </>
  );
};

export default LoteCad;