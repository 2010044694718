import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import axios from "axios";

const PesagemModal1 = ({codAcond}) => {
  const [rowData, setRowData] = useState([]);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');

  useEffect(() => {
    if (codAcond) {
      handleGetAloca();
    }
  }, [codAcond]);

  const handleGetAloca = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/planilha_peso/busca_data_aloca?codAcond=${codAcond}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  }


  const handleCheckboxChange = (index) => {
    // Definir a função setDataLotesInstruidos se necessário
    // Supondo que você quer atualizar a seleção de linhas no rowData
    setRowData(prevData => {
      const updatedData = prevData.map(row =>
        row.index === index ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const colDefs = [
    { field: "nf", headerName: "Nota Fiscal", filter: true, width: 120 ,valueFormatter: (params) => params.value || ""},
    { field: "lote", headerName: "Lote", filter: true, width: 100 ,valueFormatter: (params) => params.value || "" },
    {
      field: "reajusta", headerName: "Reajuste", filter: false, width: 130, cellRenderer: (params) => (
        <select
          id="inputState"
          className="form-select"
        >
          <option value="liquido">Liq</option>
          <option value="bruto">Bruto</option>
        </select>
      ), valueFormatter: (params) => params.value || ""
    },
    { field: "fardos", headerName: "Qtd Fardos", filter: true , width: 80 , editable: true,valueFormatter: (params) => params.value || "0"},
    { field: "tare", headerName: "Tara", filter: false, width: 100 , editable: true,valueFormatter: (params) => params.value || "0.0"},
    { field: "sgross", headerName: "Bruto CNTR", filter: false, width: 120, cellStyle: { 'background-color': 'skyblue' } , editable: true,valueFormatter: (params) => params.value || "0.0"},
    { field: "snet", headerName: "Liquido CNTR", filter: false, width: 120, cellStyle: { 'background-color': 'skyblue' } , editable: true,valueFormatter: (params) => params.value || "0.0"},
    { field: "savg", headerName: "AVG CNTR", filter: false, width: 100 ,valueFormatter: (params) => params.value || "0.0"},
    { field: "egross", headerName: "Bruto Chegada", filter: false, width: 120, cellStyle: { 'background-color': 'goldenrod' } ,editable: true ,valueFormatter: (params) => params.value || "0.0"},
    { field: "enet", headerName: "Liquido Chegada", filter: false, width: 120, cellStyle: { 'background-color': 'goldenrod' } ,editable: true ,valueFormatter: (params) => params.value || "0.0"},
    { field: "eavg", headerName: "AVG Chegada", filter: false, width: 100 ,valueFormatter: (params) => params.value || "0.0" },
    { field: "ngross", headerName: "Bruto Nota", filter: false, width: 120, cellStyle: { 'background-color': 'darkseaGreen' } ,editable: true ,valueFormatter: (params) => params.value || "0.0"},
    { field: "nnet", headerName: "Liquido Nota", filter: false,width: 120, cellStyle: { 'background-color': 'darkseaGreen' } ,editable: true ,valueFormatter: (params) => params.value || "0.0"},
    { field: "navg", headerName: "AVG Nota", filter: false, width: 100 ,valueFormatter: (params) => params.value || "0.0"},
    { field: "xgross", headerName: "Bruto Sugerido", filter: false, width: 120, cellStyle: { 'background-color': 'fuchsia' } ,editable: true,valueFormatter: (params) => params.value || "0.0"},
    { field: "xnet", headerName: "Liquido Sugerido", filter: false, width: 120, cellStyle: { 'background-color': 'fuchsia' } ,editable: true,valueFormatter: (params) => params.value || "0.0"},
    { field: "xavg", headerName: "AVG Sugerido", filter: false, width: 100 ,editable: true,valueFormatter: (params) => params.value || "0.0"},
    { field: "ctn", headerName: "CNTR-Nota", filter: false, width: 100 ,editable: true,valueFormatter: (params) => params.value || "0.0"},
    { field: "", headerName: "%", filter: false, width: 100 ,valueFormatter: (params) => params.value || ""},
    { field: "chn", headerName: "Chegada-Nota", filter: true, width: 100 ,valueFormatter: (params) => params.value || "0.0"},
    { field: "", headerName: "%", filter: false, width: 100 ,valueFormatter: (params) => params.value || ""},
    { field: "sn", headerName: "Sugerido-Nota", filter: true, width: 100 ,valueFormatter: (params) => params.value || "0.0"},
    { field: "", headerName: "%", filter: false, width: 100 ,valueFormatter: (params) => params.value || ""},
    {
      field: "peso", headerName: "Peso Utilizado", filter: false, width: 130, cellRenderer: (params) => (
        <select
          id="inputState"
          className="form-select"
          value={params.value}
        >
          <option value="saida">Saida</option>
          <option value="entrada">Entrada</option>
          <option value="nota">Nota</option>
          <option value="sugerido">Sugerido</option>
        </select>
      ), valueFormatter: (params) => params.value || ""
    },
  ];
  
  const calculateColumnSum = (columnName) => {
    if (!rowData || rowData.length === 0) {
      return 0; // Retorna 0 se não houver dados ainda
    }
    return rowData.reduce((sum, currentRow) => {
      return sum + parseFloat(currentRow[columnName] || 0);
    }, 0).toFixed(2);
  };

  const totalRow = {
    nf: "Total",
    fardos: calculateColumnSum("fardos"),
    tare: calculateColumnSum("tare"),
    sgross: calculateColumnSum("sgross"),
    snet: calculateColumnSum("snet"),
    savg: calculateColumnSum("savg"),
    egross: calculateColumnSum("egross"),
    enet: calculateColumnSum("enet"),
    eavg: calculateColumnSum("eavg"),
    ngross: calculateColumnSum("ngross"),
    nnet: calculateColumnSum("nnet"),
    navg: calculateColumnSum("navg"),
    xgross: calculateColumnSum("xgross"),
    xnet: calculateColumnSum("xnet"),
    xavg: calculateColumnSum("xavg"),
    ctn: calculateColumnSum("ctn"),
    chn: calculateColumnSum("chn"),
    sn: calculateColumnSum("sn"),
    peso: "", // Você pode deixar em branco ou calcular conforme necessário
  };

  const rowDataWithTotal = [...rowData, totalRow];

  

  return (
    <>
            <div class="modal fade" id="PesagemModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Fardos</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                <AgGridReact
                    rowData={rowDataWithTotal}
                    columnDefs={colDefs}
                    rowSelection="unique"
                    onSelectionChanged={() => console.log('Row Selected!')}
                    rowClassRules={{
                      'total-row': (params) => params.data.nracond === 'Total'
                    }}
                    getRowNodeId={(data) => data.nracond}
                />
                </div>
                <div><hr class="divider bg-custom"/></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Salvar</button>
            </div>
            </div>
        </div>
        </div>
      
    </>
  );
};

export default PesagemModal1;
