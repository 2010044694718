import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const ProtocoloEnt = () => {
  const navigate = useNavigate();

  return (
        <>
        <form class="g-3">
                    <div class="col-md-7">
                        <label for="razaosocial" class="form-label ">Para:</label>
                        <input type="text" class="form-control transparent-input" id="razaosocial"/>
                    </div>
                    <div class="col-md-7">
                        <label for="nomefantasia" class="form-label">A/c:</label>
                        <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                    </div>
                    <div class="col-md-3">
                        <label for="nomefantasia" class="form-label">Data de Entrega</label>
                        <input type="date" class="form-control transparent-input " id="nomefantasia"/>
                    </div>
                    <legend className="form-label2 my-2">Packing List</legend>
                    <div className="row 12">
                    <div class="col-md-6">
                        <label for="razaosocial" class="form-label ">Originais</label>
                        <input type="text" class="form-control transparent-input" id="razaosocial"/>
                    </div>
                    <div class="col-md-6">
                        <label for="nomefantasia" class="form-label">Cópias</label>
                        <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                    </div>
                    <legend className="form-label2 my-2">Certificado de Peso</legend>
                    <div class="col-md-6">
                        <label for="razaosocial" class="form-label ">Originais</label>
                        <input type="text" class="form-control transparent-input" id="razaosocial"/>
                    </div>
                    <div class="col-md-6">
                        <label for="nomefantasia" class="form-label">Cópias</label>
                        <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                    </div>
                    </div>
                    <button type="submit" class="btn btn-warning my-3">Imprimir Protocolo</button>
            <div class="col-12">
                <button type="submit" class="btn btn-success">Salvar</button>
                <button type="submit" class="btn mx-2 btn-primary">Imprimir</button>
            </div>
            </form>
        </>
  );
};

export default ProtocoloEnt;