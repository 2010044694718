import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import { Checkbox, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';


const IntegracaoNF = () => {
  const navigate = useNavigate();
  const [seach, setSeach] = useState(null)
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const parsedURL = localStorage.getItem('urlParsed');
  



  const handleConsultListRoman = async () => {
    const token = localStorage.getItem('token');
    let apiUrl = '';

    switch (selectedOption) {
      
      case 'nf':
        apiUrl = `${parsedURL}get/romaneio_eletronico/NF?nf=${seach}`;
        break;
      case 'lote':
        apiUrl = `${parsedURL}get/romaneio_eletronico/LOTE?lote=${seach}`;
        break;
      case 'ie':
        apiUrl = `${parsedURL}get/romaneio_eletronico/IE?ie=${seach}`;
        break;
      case 'fardo':
        apiUrl = `${parsedURL}get/romaneio_eletronico/FARDO?bale=${seach}`;
        break;
      default:
        break;
    }
    toast.info('Verficando Romaneio', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os dados:', error);
    }
  };

  const fetchDownloadResume = () => {
    toast.info('Gerando romaneio eletronico!', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

    const token = localStorage.getItem('token');
    let apiUrl = '';

    switch (selectedOption) {
      case 'nf':
        apiUrl = `${parsedURL}get/romaneio_eletronico/download/NF?nf=${seach}`;
        break;
      case 'lote':
        apiUrl = `${parsedURL}get/romaneio_eletronico/download/LOTE?lote=${seach}`;
        break;
      case 'ie':
        apiUrl = `${parsedURL}get/romaneio_eletronico/download/IE?ie=${seach}`;
        break;
      case 'fardo':
        apiUrl = `${parsedURL}get/romaneio_eletronico/download/FARDO?bale=${seach}`;
        break;
      default:
        break;
    }

    axios({
      method: 'GET',
      url: apiUrl,
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })

      // Código anterior ...

      .then(response => {
        console.log('Resposta recebida:', response);

        // Extrair o nome do arquivo do cabeçalho content-disposition
        const contentDisposition = response.headers['content-disposition'];
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        let fileName = matches && matches[1] ? matches[1] : `Romaneio_Unificado_${selectedOption}_${seach}.xlsx`;

        // Substituir '/' por '-'
        if (fileName) {
          fileName = fileName.replace(/\//g, '-');
        }

        // Criar um URL temporário para o blob de dados
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

        // Criar um elemento de âncora para iniciar o download
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = fileName;

        // Simular um clique no link para iniciar o download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Liberar o URL temporário
        window.URL.revokeObjectURL(blobUrl);
        toast.success('Romaneio Gerado com Sucesso!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });


      })
      .catch(error => {

      });

  };


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Impedir o comportamento padrão do formulário
      handleConsultListRoman();
    }
  };

  const handleChangeFiltro = (e) => {
    if (e.target.name === 'seach') {
      setSeach(e.target.value);
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCheckboxChange = (id) => {
    setData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.id === id ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const columns = [
    { field: "id", headerName: "Id", width: 50 },
    { field: "baleid", headerName: "Bale Id (6)", width: 100 },
    { field: "balefull", headerName: "Bale Full (20)", width: 200 },
    { field: "nf", headerName: "Nota Fiscal", width: 150 },
    { field: "lote", headerName: "Lote", width: 150 },
    { field: "ie", headerName: "Instrução de embarque", width: 200 },
  ];

  const rows = data.map((row, index) => ({
    id: index + 1,
    baleid: row.baleid,
    balefull: row.balefull,
    nf: row.nf,
    lote: row.lote,
    ie: row.ie,
  }));

  console.log('Columns:', columns);
  console.log('Rows:', rows);


  return (
    <>
      <Navbar />
      <>
        <div className="box-center">
          <form class="row g-3 py-2">
            <div class="col-md-4">
              <label for="inputState" class="form-label hfx">Tipo</label>
              <select id="inputState" class="form-select"
                onChange={handleSelectChange}
                value={selectedOption}>
                <option selected>Selecione a opção</option>
                <option value="nf">NF</option>
                <option value="lote">LOTE</option>
                <option value="ie">IE</option>
                <option value="fardo">FARDO</option>
              </select>
            </div>
            <div class="col-md-8">
              <label class="hfx form-label">Busca</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="seach"
                  value={seach}
                  onChange={handleChangeFiltro}
                  onKeyPress={handleKeyPress} // Call handleConsultListRoman on Enter key press
                  aria-label="Input group example"
                  aria-describedby="btnGroupAddon2"
                />            </div>
            </div>
          </form>
          <div><hr className="divider bg-custom" /></div>
          <div className="my-3 transparent-input" style={{ height: 500, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} pageSizer={5} />
          </div>
          <button type="button" class="btn btn-warning" onClick={fetchDownloadResume}>Exportar Excel</button>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>
    </>
  );
};

export default IntegracaoNF;
