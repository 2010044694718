import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../style/style.css";
import { NavigateNext, Search } from '@mui/icons-material';
import VizFinal1 from "./viz_final_modal1";


const VizualizarFinal = () => {
  const [rowData, setRowData] = useState([
    { mission: "Voyager", company: "NASA", location: "Cape Canaveral", date: "1977-09-05", rocket: "Titan-Centaur ", price: 86580000, successful: true, selected: false },
    { mission: "Apollo 13", company: "NASA", location: "Kennedy Space Center", date: "1970-04-11", rocket: "Saturn V", price: 3750000, successful: false, selected: false },
    { mission: "Falcon 9", company: "SpaceX", location: "Cape Canaveral", date: "2015-12-22", rocket: "Falcon 9", price: 9750000, successful: true, selected: false }
  ]);

   const handleCheckboxChange = (mission) => {
    setRowData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.mission === mission ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const handleAddRow = () => {
    const newMission = `New Mission ${rowData.length + 1}`;

    setRowData((prevData) => [
      ...prevData,
      {
        mission: newMission,
        company: "New Company",
        location: "New Location",
        date: "2024-01-08",
        rocket: "New Rocket",
        price: 0,
        successful: false,
        selected: false,
      },
    ]);
  };
  const colDefs = [
    { headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
    { field: "mission", headerName: "Mission", filter: true, width: 130 },
    { field: "company", headerName: "Company", filter: true, width: 150 },
    { field: "location", headerName: "Location", filter: true , width: 200},
    { field: "date", headerName: "Date", filter: true, width:150},
    { field: "price", headerName: "Price", filter: true, width: 150},
    { field: "rocket", headerName: "Rocket", filter: true },
    { field: "rocket", headerName: "Rocket", filter: true, width: 90 , cellRenderer: (params) => (
      <button type="submit" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#PesagemModal1" onClick={() => handleCheckboxChange(params.value)}><NavigateNext/></button>
    )} 
  ];

  return (
        <>
        <div className="ag-theme-quartz" style={{ height: 300 }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            rowSelection="multiple"
            onSelectionChanged={() => console.log('Row Selected!')}
          />
        </div>
      <div class="row g-1">
        <div class="col-md-2">
        <label for="emailfat" class="form-label hfx4">Fardos Utilizados</label>
        <input type="text" class="form-control transparent-input rounded form-control-sm"/>
      </div>
      <div class="col-md-2">
        <label for="emailfat" class="form-label hfx4">Gross Weigth</label>
        <input type="text" class="form-control transparent-input rounded form-control-sm"/>
      </div>
      </div>
      <button type="button" class="my-2 btn btn-success">Abrir planilhas de container/Lacres</button>
      <div><hr class="divider bg-custom"/></div>
      <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active form-label" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-10" type="button" role="tab" aria-controls="nav-1" aria-selected="true">Planilha Detalhe</button>
                <button class="nav-link form-label" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-20" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Limite de Peso</button>
            </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active " id="nav-10" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
            <VizFinal1/>
            </div>
            <div class="tab-pane fade" id="nav-20" role="tabpanel" aria-labelledby="nav-2-tab" tabindex="0">
            ...
            </div>
            </div>
        <div class="row g-1">
        <div class="col-md-2">
        <label for="emailfat" class="form-label hfx4">Fardos NF</label>
        <input type="text" class="form-control transparent-input rounded form-control-sm"/>
      </div>
      <div class="col-md-2">
        <label for="emailfat" class="form-label hfx4">Disponiveis</label>
        <input type="text" class="form-control transparent-input rounded form-control-sm"/>
      </div>
      </div> 
      
        </>
  );
};

export default VizualizarFinal;