import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Navbar from "../navbar/navbar";
import Search from "@mui/icons-material/Search";
import { Checkbox } from "@mui/material";
import PesquisaEntidade from "../ordem_servico/pesquisa_entidade";

const CadastrarNF = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  
   
  const getConsultaOS = async () => {
    try {
      const response = await axios.get("https://www.balldontlie.io/api/v1/teams");
      setData(response.data.data.map(row => ({ ...row, selected: false })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getConsultaOS();
  }, []);

  const handleCheckboxChange = (id) => {
    setData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.id === id ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.selected}
          onChange={() => handleCheckboxChange(params.row.id)}
        />
      ),
    },
    { field: "city", headerName: "City", width: 150 },
    { field: "abbreviation", headerName: "Abbreviation", width: 150 },
    { field: "conference", headerName: "Conference", width: 150 },
    { field: "division", headerName: "Division", width: 150 },
  ];

  const rows = data.map((row) => ({
    id: row.id,
    abbreviation: row.abbreviation,
    city: row.city,
    conference: row.conference,
    division: row.division,
  }));

  console.log(data);

  return (
        <>
        <Navbar></Navbar>
        <form className="box-center">
            <div class="">
            <div class="row align-items-start">
                <div class="row col">
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-3 col-form-label hfx ">Nota Fiscal</label>
                    <div class="col-sm-9">
                    <input type="email" class="form-control transparent-input" id="inputEmail3"/>
                    </div>
                </div>
                <div><hr class="divider bg-custom "/></div>
                <div class="mb-3">
                <label for="inputEmail3" class="hfx form-label">Emissor / Produtor</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button type="button" class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search/></button>
                </div>
                </div>
                <div class="col-md-4">
                <label for="razaosocial" class="form-label hfx">Data de Emissão</label>
                <input type="date" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-4">
                <label for="razaosocial" class="form-label hfx">Hora de Emissão</label>
                <input type="time" class="form-control transparent-input" id="razaosocial"/>
                </div>
                </div>
                <div class="row col">
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-4 col-form-label hfx">Código Caminhão</label>
                    <div class="col-sm-8">
                    <input type="email" class="form-control transparent-input" id="inputEmail3"/>
                    </div>
                </div>
                <div><hr class="divider bg-custom "/></div>
                <div class="mb-3">
                <label for="inputEmail3" class="hfx form-label ">Terminal</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button type="button" class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search/></button>
                </div>
                </div>
                <div class="col-md-3">
                <label for="razaosocial" class="form-label hfx ">Peso Bruto</label>
                <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                <label for="razaosocial" class="form-label hfx ">Peso líquido</label>
                <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-6">
                <label for="razaosocial" class="form-label hfx">Média de Fardos da NF (Kg)</label>
                <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                </div>
            </div>
            </div>
            <div><hr class="divider bg-custom "/></div>
            <div  className="my-3 transparent-input" style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
        <button type="button" class="btn btn-success">+</button>
        <button type="button" class="btn btn-danger mx-1">-</button>
        <div><hr class="divider bg-custom "/></div>
        <button type="button" class="btn btn-primary">Criar Nota Fiscal</button>
        <PesquisaEntidade></PesquisaEntidade>
        </form>
        
        </>
  );
};

export default CadastrarNF;