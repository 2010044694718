import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";


const StatusObsOs = ({ondataos,onhandleChange,onSetDataOs,onbuttonSave}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);


  useEffect(() => {
  }, []);

  const columns = [
    { field: "city", headerName: "City", width: 150 },
    { field: "abbreviation", headerName: "Abbreviation", width: 150 },
    { field: "conference", headerName: "Conference", width: 150 },
    { field: "division", headerName: "Division", width: 150 },
  ];

  return (
    <>
      <form class="row g-3 py-2">
        <div class="col-md-2 ">
          <label class="form-label">Envio de Relatório de Peso</label>
          <input type="text" readOnly={true} class="form-control transparent-input" value={ondataos.sta_envioPeso} />
        </div>
        <div class="col-md-2">
          <label for="telefone" class="form-label ">Envio de Draft</label>
          <input type="text" readOnly={true} class="form-control transparent-input" value={ondataos.sta_envioDraft} />
        </div>
        <div class="col-md-2">
          <label class="form-label">Emissão de Certificado Original</label>
          <input type="text" readOnly={true} class="form-control transparent-input" value={ondataos.sta_certificado}/>
        </div>
        <div class="col-md-2">
          <label class="form-label">Faturado</label>
          <input type="text" readOnly={true} class="form-control transparent-input" value={ondataos.data_envio_fat} />
        </div>
        <div class="col-md-2">
          <label class="form-label">Pago(Processo Finalizado)</label>
          <input type="text" readOnly={true} class="form-control transparent-input" value={ondataos.sta_pago} />
        </div>
        <div class="col-md-12">
          <label for="exampleFormControlTextarea1" class="form-label">Observação</label>
          <textarea value={ondataos.obs} class="form-control transparent-input" rows="7" onChange={onhandleChange} name="obs"></textarea>
        </div>
        <div><hr class="divider bg-custom " /></div>
        {/*<div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            rowSelection="unique"
        />
        </div>*/}
          <div class="col-12">
          <button type="button" class="btn btn-primary" onClick={onbuttonSave}>Salvar</button>
        </div>
      </form>
    </>
  );
};

export default StatusObsOs;