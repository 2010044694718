import React, { useState, useEffect} from 'react';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';

const PLModal = ({aloca}) => {
  const [rowData, setRowData] = useState([
  ]);

  useEffect(() => {
    console.log(aloca);
  }, [aloca]);

   const handleCheckboxChange = (mission) => {
    setRowData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.mission === mission ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const handleAddRow = () => {
    const newMission = `New Mission ${rowData.length + 1}`;

    setRowData((prevData) => [
      ...prevData,
      {
        mission: newMission,
        company: "New Company",
        location: "New Location",
        date: "2024-01-08",
        rocket: "New Rocket",
        price: 0,
        successful: false,
        selected: false,
      },
    ]);
  };
  const colDefs = [
    { headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
    { field: "mission", headerName: aloca, filter: true, width: 120 },
    { field: "company", headerName: "Company", filter: true, width: 100 },
    { field: "location", headerName: "Location", filter: true , width: 80},
    { field: "date", headerName: "Date", filter: true, width: 80 },
    { field: "price", headerName: "Price", filter: true, width: 80 },
    { field: "rocket", headerName: "Rocket", filter: true },
    {/* field: "rocket", headerName: "Rocket", filter: true, width: 70 , cellRenderer: (params) => (
      <button type="submit" class="btn btn-primary btn-sm" onClick={() => handleCheckboxChange(params.value)}><Search/></button>
    )*/}
  ];

  return (
    <>
            <div class="modal fade" id="PLModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Fardos</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowSelection="unique"
                    onSelectionChanged={() => console.log('Row Selected!')}
                />
                </div>
                <div><hr class="divider bg-custom"/></div>
                <button type="submit" class="btn btn-primary" onClick={handleAddRow}>Adicionar Linha</button>
            
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Ok</button>
            </div>
            </div>
        </div>
        </div>
      
    </>
  );
};

export default PLModal;
