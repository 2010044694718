import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import Instrucoes from "./instrucoes";
import DemoCalculo from "./demonstrativo_calculo";
import Calculo from "./calculo";

const Faturamento = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
                <div className="box-center">
                <div>
            <legend className="hfx">Demonstrativo de Cálculo</legend>
                    <form class="row g-3">
                <div class="col-md-7">
                    <label for="razaosocial" class="form-label ">Certificado</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-5">
                    <label for="nomefantasia" class="form-label">Senha</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                </form>
            </div>
            <nav>
            <div class="nav nav-tabs my-3" id="nav-tab" role="tablist">
                <button class="nav-link active form-label" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Instruções</button>
                <button class="nav-link form-label" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Demonstrativo de Cálculo</button>
                <button class="nav-link form-label" id="nav-num-tab" data-bs-toggle="tab" data-bs-target="#nav-num" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Calcular</button>
            </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
            <form class="row g-3">
            <Instrucoes></Instrucoes>
            <div class="col-12">
                <button type="submit" class="btn btn-success">Salvar</button>
                <button type="submit" class="btn mx-2 btn-secondary">Imprimir</button>
            </div>
            </form>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
            <DemoCalculo></DemoCalculo> 
            </div>
            <div class="tab-pane fade" id="nav-num" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
            <Calculo></Calculo>
            </div>
            </div></div>
            
        </>
  );
};

export default Faturamento;