import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Search from "@mui/icons-material/Search";

const DadosFat = ({ondataos,onSetDataOs,onhandleChange,onhandleClickSeach,onbuttonSave}) => {

  return (
    <>
      <form class="row g-3 py-4">
        <div class="container">
          <div class="row align-items-start">
            <div class="col">
              <div class="mb-3">
                <label class="hfx form-label">Faturar Para</label>
                <div class="input-group">
                  <input readOnly={true} value={ondataos.faturar} type="text" class="form-control transparent-input" />
                  <button type="button" class="input-group-text btn-primary btn" onClick={() => onhandleClickSeach("codFaturarP")} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label class="hfx form-label">Enviar Faturamento Para</label>
                <div class="input-group">
                  <input readOnly={true} value={ondataos.enviar} type="text" class="form-control transparent-input"/>
                  <button type="button" class="input-group-text btn-primary btn "  onClick={() => onhandleClickSeach("CodEnviarP")} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="form-label">Oberservação Faturamento</label>
          <textarea class="form-control transparent-input" value={ondataos.obsfat} rows="7"  onChange={onhandleChange} name="obsfat"></textarea>
        </div>
        <div class="col-12">
          <button type="button" class="btn btn-primary" onClick={onbuttonSave}>Salvar</button>
        </div>
      </form>
    </>
  );
};

export default DadosFat;