import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Search, CancelOutlined, FileDownload, Save } from "@mui/icons-material";
import { Checkbox } from "@mui/material";

const LoteConsult = () => {
    const [data, setData] = useState([]);
  
   
    const getConsultaOS = async () => {
      try {
        const response = await axios.get("https://www.balldontlie.io/api/v1/teams");
        setData(response.data.data.map(row => ({ ...row, selected: false })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      getConsultaOS();
    }, []);
  
    const handleCheckboxChange = (id) => {
      setData((prevData) => {
        const updatedData = prevData.map((row) =>
          row.id === id ? { ...row, selected: !row.selected } : row
        );
        return updatedData;
      });
    };
  
    const columns = [
      {
        field: "id",
        headerName: "ID",
        width: 90,
        renderCell: (params) => (
          <Checkbox
            checked={params.row.selected}
            onChange={() => handleCheckboxChange(params.row.id)}
          />
        ),
      },
      { field: "city", headerName: "City", width: 150 },
      { field: "abbreviation", headerName: "Abbreviation", width: 150 },
      { field: "conference", headerName: "Conference", width: 150 },
      { field: "division", headerName: "Division", width: 150 },
    ];
  
    const rows = data.map((row) => ({
      id: row.id,
      abbreviation: row.abbreviation,
      city: row.city,
      conference: row.conference,
      division: row.division,
    }));
  
    console.log(data);

  return (
        <>
        <Navbar/>
        <form className="box-center container">
            <legend class="hfx">Pesquisar Lote</legend>
        <form class="row g-3 container">
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label ">Lote</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="nomefantasia" class="form-label">Contrato</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-2">
                    <label for="nomefantasia" class="form-label">Safra</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-2">
                    <label for="nomefantasia" class="form-label">Tara</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div class="col-md-2">
                    <label for="nomefantasia" class="form-label">Nota Fiscal</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
                </div>
                <div><hr class="divider bg-custom "/></div>
                <div class="col-md-12">
                <label for="inputEmail3" class="col-sm-2 form-label">Cliente ou Produtor</label>
                <div class="input-group">
                <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2"><Search></Search></button>
            </div>
            </div>
            <div class="col-md-12">
                <div class=" text-end">
                    <div class="row align-items-start hfx">
                        <div class="col-9">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                            <label class="form-check-label" for="inlineRadio1">Cliente</label>
                            </div>
                        </div>
                        <div class="col-1">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                            <label class="form-check-label" for="inlineRadio2">Produtor</label>
                            </div>
                        </div>
                        <div class="col-2">
                        <button type="button" class="btn btn-primary"><Search></Search> Pesquisar</button>
                        </div>
                    </div>
                    </div>
                </div>
                </form>
                <div><hr class="divider bg-custom "/></div>
  	<div  className="my-3 transparent-input" style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
                <div><hr class="divider bg-custom "/></div>
                <div class="row align-items-start">
                            <div class="col">
                            <button type="button" class="btn btn-success"><FileDownload></FileDownload> Exportar Pesquisa</button>
                            </div>
                            <div class="col text-end">
                            <button type="button" class="btn btn-danger mx-2"><CancelOutlined></CancelOutlined> Cancelar</button>
                            <button type="button" class="btn btn-primary"><Save></Save> Salvar</button>
                            </div>
                        </div>
        </form>
        </>
  );
};

export default LoteConsult;