import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Navbar from "../navbar/navbar";
import "../../style/style.css"

const Cadastro_Entidade = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      razao: "",
      fantasia: "",
      Tipo: "",
      endereco: "",
      cidade: "",
      uf: "",
      tipoDoc: "",
      doc: "",
      insc_est: "",
      fone: "",
      fax: "",
      contato: "",
      cel: "",
      email: "",
      resp_fat: "",
      email_fat: "",
      fone_fat: "",
      Inadimplente: false,
      obsEntidade: ""
  });

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await fetch("/post/cad/entidade/new_entidade", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify(formData)
          });
          if (response.ok) {
              const data = await response.json();
              console.log(data);
              // Redirecionar ou fazer algo após o sucesso do cadastro
              //history.push("/sucesso");
          } else {
              throw new Error("Erro ao cadastrar entidade");
          }
      } catch (error) {
          console.error("Erro ao cadastrar entidade:", error);
          // Lidar com erros de forma adequada
      }
  };


  return ( <>
  <Navbar></Navbar>
      <div className="box-center">
      <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active form-label" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Cadastro</button>
                <button class="nav-link form-label" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Outros</button>
                <button class="nav-link form-label" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Outros</button>
            </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
            <legend className="form-label p-2">Cadastro de Entidade</legend>
    <form class="row g-3">
  <div class="col-md-8">
    <label for="razaosocial" class="form-label ">Razão Social</label>
    <input type="text" class="form-control transparent-input " name="razao" value={formData.razao} onChange={handleChange} />
  </div>
  <div class="col-md-4">
    <label for="nomefantasia" class="form-label">Nome Fantasia</label>
    <input type="text" class="form-control transparent-input " name="fantasia" value={formData.fantasia} onChange={handleChange}/>
  </div>
  <div class="col-12">
    <label for="tipoentidade" class="form-label">Tipo de Entidade</label>
    <input type="text" class="form-control transparent-input" name="Tipo" value={formData.Tipo} onChange={handleChange} placeholder="CLIENTE, CORRETOR, DESPACHANTE, EXPORTADOR, PRODUTOR, TERMINAL, TRADER"/>
  </div>
  <div class="col-md-7">
  <label for="exampleFormControlTextarea1" class="form-label">Endereço</label>
  <textarea class="form-control transparent-input" name="endereco" value={formData.endereco} onChange={handleChange} rows="7"></textarea>
  </div>
  <div class="col-md-5 ">

    <label for="inputState" class="form-label ">Tipo de Documento</label>
    <select name="tipoDoc" value={formData.tipoDoc} onChange={handleChange} class="form-select transparent-input">
      <option selected></option>
      <option>CPF</option>
      <option>CNPJ</option>
    </select>
    
    <div class="col-md-8 ">
      
    <label for="numdocumento" class="form-label">Número do Documento</label>
    <input type="password" class="form-control transparent-input" name="razao" value={formData.razao} onChange={handleChange}/>
  </div>
    <div class="col-md-12 ">
    <label for="iscestadual" class="form-label">Inscrição Estadual</label>
    <input type="password" class="form-control transparent-input" name="razao" value={formData.razao} onChange={handleChange}/>
  </div>
  </div>
  <div class="col-md-3">
    <label for="inputCity" class="form-label">Cidade</label>
    <input type="text" class="form-control" name="cidade" value={formData.cidade} onChange={handleChange}/>
  </div>
  <div class="col-md-3">
    <label for="inputState" class="form-label">Estado</label>
    <select name="uf" value={formData.uf} onChange={handleChange} class="form-select" >
      <option selected>Selecione o Estado</option>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
    </select>
  </div>
  <div class="col-md-3">
    <label for="telefone" class="form-label">Telefone</label>
    <input type="text" class="form-control"  name="razao" value={formData.razao} onChange={handleChange} placeholder="(00)00000-0000"/>
  </div>
  <div class="col-md-3">
    <label for="inputZip" class="form-label">Fax</label>
    <input type="text" class="form-control" name="razao" value={formData.razao} onChange={handleChange}/>
  </div>
  <div class="col-md-3">
    <label for="inputCity" class="form-label">Contato</label>
    <input type="text" class="form-control"name="razao" value={formData.razao} onChange={handleChange}/>
  </div>
  <div class="col-md-3">
    <label for="telefone" class="form-label">Celular</label>
    <input type="number" class="form-control" name="razao" value={formData.razao} onChange={handleChange} placeholder="(00)00000-0000"/>
  </div>
  <div class="col-md-6">
    <label for="inputCity" class="form-label">Email</label>
    <input type="email" class="form-control" name="razao" value={formData.razao} onChange={handleChange}/>
  </div>
  <div class="col-md-3">
    <label for="inputCity" class="form-label">Responsável Faturamento</label>
    <input type="text" class="form-control" name="razao" value={formData.razao} onChange={handleChange}/>
  </div>
  <div class="col-md-3">
    <label for="telefone" class="form-label">Telefone Faturamento</label>
    <input type="number" class="form-control" name="razao" value={formData.razao} onChange={handleChange} placeholder="(00)00000-0000"/>
  </div>
  <div class="col-md-6">
    <label for="emailfat" class="form-label">Email Faturamento</label>
    <input type="text" class="form-control" name="razao" value={formData.razao} onChange={handleChange}/>
  </div>
  <div class="col-md-12">
  <label for="exampleFormControlTextarea1" class="form-label">Observações</label>
  <textarea class="form-control transparent-input" name="razao" value={formData.razao} onChange={handleChange} rows="7"></textarea>
  </div> 
  <div class="col-12">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" name="razao" value={formData.razao} onChange={handleChange}/>
      <label class="form-check-label hfx" for="gridCheck">
        Inadimplente
      </label>
    </div>
  </div>
  <div class="col-12">
    <button type="submit" class="btn btn-success">Salvar</button>
    <button type="submit" class="btn mx-2 btn-secondary">Imprimir</button>
  </div>
  
</form>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">...</div>
            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">...</div>
            </div>
                {/*<legend className="form-label">Cadastro de Entidade</legend>
    <form class="row g-3">
  <div class="col-md-8">
    <label for="razaosocial" class="form-label ">Razão Social</label>
    <input type="text" class="form-control transparent-input" id="razaosocial"/>
  </div>
  <div class="col-md-4">
    <label for="nomefantasia" class="form-label">Nome Fantasia</label>
    <input type="text" class="form-control transparent-input " id="nomefantasia"/>
  </div>
  <div class="col-12">
    <label for="tipoentidade" class="form-label">Tipo de Entidade</label>
    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder="CLIENTE, CORRETOR, DESPACHANTE, EXPORTADOR, PRODUTOR, TERMINAL, TRADER"/>
  </div>
  <div class="col-md-7">
  <label for="exampleFormControlTextarea1" class="form-label">Endereço</label>
  <textarea class="form-control transparent-input" id="exampleFormControlTextarea1" rows="7"></textarea>
  </div>
  <div class="col-md-5 ">

    <label for="inputState" class="form-label ">Tipo de Documento</label>
    <select id="inputState" class="form-select transparent-input">
      <option selected></option>
      <option>CPF</option>
      <option>CNPJ</option>
    </select>
    
    <div class="col-md-12 ">
    <label for="numdocumento" class="form-label">Número do Documento</label>
    <input type="password" class="form-control transparent-input" id="numdocumento" placeholder="000.000.000-00 | 00.000.000/0000-00"/>
    <div class="col-md-12 ">
    <label for="iscestadual" class="form-label">Inscrição Estadual</label>
    <input type="password" class="form-control transparent-input" id="iscestadual" placeholder="000.000.000.000"/>
  </div>
  </div>
  </div>
  <div class="col-md-3">
    <label for="inputCity" class="form-label">Cidade</label>
    <input type="text" class="form-control" id="inputCity"/>
  </div>
  <div class="col-md-3">
    <label for="inputState" class="form-label">Estado</label>
    <select id="inputState" class="form-select">
      <option selected>Selecione o Estado</option>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
    </select>
  </div>
  <div class="col-md-3">
    <label for="telefone" class="form-label">Telefone</label>
    <input type="text" class="form-control" id="telefone" placeholder="(00)00000-0000"/>
  </div>
  <div class="col-md-3">
    <label for="inputZip" class="form-label">Fax</label>
    <input type="text" class="form-control" id="inputZip"/>
  </div>
  <div class="col-md-3">
    <label for="inputCity" class="form-label">Contato</label>
    <input type="text" class="form-control" id="inputCity"/>
  </div>
  <div class="col-md-3">
    <label for="telefone" class="form-label">Celular</label>
    <input type="number" class="form-control" id="telefone" placeholder="(00)00000-0000"/>
  </div>
  <div class="col-md-6">
    <label for="inputCity" class="form-label">Email</label>
    <input type="email" class="form-control" id="inputCity"/>
  </div>
  <div class="col-md-3">
    <label for="inputCity" class="form-label">Responsável Faturamento</label>
    <input type="text" class="form-control" id="inputCity"/>
  </div>
  <div class="col-md-3">
    <label for="telefone" class="form-label">Telefone Faturamento</label>
    <input type="number" class="form-control" id="telefone" placeholder="(00)00000-0000"/>
  </div>
  <div class="col-md-6">
    <label for="emailfat" class="form-label">Email Faturamento</label>
    <input type="text" class="form-control" id="emailfat"/>
  </div>
  <div class="col-md-12">
  <label for="exampleFormControlTextarea1" class="form-label">Observações</label>
  <textarea class="form-control transparent-input" id="exampleFormControlTextarea1" rows="7"></textarea>
  </div> 
  <div class="col-12">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="gridCheck"/>
      <label class="form-check-label" for="gridCheck">
        Inadimplente
      </label>
    </div>
  </div>
  <div class="col-12">
    <button type="submit" class="btn btn-success">Salvar</button>
    <button type="submit" class="btn mx-2 btn-secondary">Imprimir</button>
  </div>
  
  </form>*/}

    </div>
    </>
    

  );
};

export default Cadastro_Entidade;