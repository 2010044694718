import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"

const Frase = () => {
  const navigate = useNavigate();

  // Estado para armazenar frases na área superior
  const [frasesSuperior, setFrasesSuperior] = useState([]);

  // Estado para armazenar frases na área inferior
  const [frasesInferior, setFrasesInferior] = useState([
    "1. This certificate respects the rules and laws 'International Cotton Association.",
    "2. This report relates to the supervision of weighing and to tallying of bales during loading on dates(s) and at the location(s) indicated only. This certificate is issued without prejudice.",
    "3. The calculated weights are based on the average weights ascertained at the time of weighing of the full truck on its arrival and empty truck on its departure at the location(s) mentioned.",
    "4. This certificate reflects the findings determined at time and place of our intervention only.",
    "5. We certify that the stuffed bales were not showing any dirty damage and visually the bales were not showing any damage in its outside/cover at the time of stuffing. We also certify that the containers have been sealed without any visual external damage.",
    "6. After the stuffing, the containers were sealed by AG Surveyors",
    "7. AG Surveyors were not in attendance at time of placement of shipping company seals.",
    "8. Attached is the Packing List bale by bale of each container of this instruction.",
    "9. Shipping Instructions: XXXX",
    "10. Shipping Marks: XXXX",
    "11. The calculated weights are based on the average weights ascertained at the time of full/empty truck weighing on arrival of the bales at the location(s) mentioned.",
    "12. We certify that containers have been sealed without any visual external damage and/or country damage on stuffed bales.",
    "13. After the loading, the containers were sealed by AG Surveyors."
    // ... Adicione as outras frases aqui
  ]);

  // Função para mover a frase da área inferior para a área superior
  const moverParaSuperior = (frase) => {
    setFrasesSuperior([...frasesSuperior, frase]);
    setFrasesInferior(frasesInferior.filter((f) => f !== frase));
  };
  // Função para remover uma frase da área superior
  const removerDaSuperior = (frase) => {
    setFrasesSuperior(frasesSuperior.filter((f) => f !== frase));
    setFrasesInferior([...frasesInferior, frase]);
  };

  return (
    <>
      <div className="box-center">
        <div className="row g-2">
          <div className="col-md-12">
            {/* Área de texto superior */}
            <div className="frases-superior-container mb-2">
              {frasesSuperior.map((frase, index) => (
                <div key={index} className="frase-superior">
                  <span>{frase}</span>
                  <button
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => removerDaSuperior(frase)}
                  >
                    Remover
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-12">
            {/* Área de texto inferior */}
            {frasesInferior.map((frase, index) => (
              <div
                key={index}
                className="frase-inferior form-label"
                onClick={() => moverParaSuperior(frase)}
              >
                {frase}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Frase;