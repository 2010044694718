import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import "../../style/style.css";

const RelatorioFModal = ({ codOrder, isOpen }) => {
  const [selectedOption, setSelectedOption] = useState(null); // Para o select
  const [uploadedImages, setUploadedImages] = useState([]); // Para os arquivos enviados
  const [containerList, setContainerList] = useState([]); // Para a lista de containers
  const [imageCount, setImageCount] = useState(null); // Para os radiobuttons (quantidade de imagens)
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');


  useEffect(() => {
    if (isOpen) {
      handleGetContainersIsNull();
    }
  }, [isOpen]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedImages(files);
  };

  const handleGetContainersIsNull = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/relatorio_fotografico/containers_is_null?codos=${codOrder}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setContainerList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveContainer = async () => {
    if (imageCount === null) {
      toast.error("Selecione a quantidade de imagens esperadas.");
      return;
    }

    if (uploadedImages.length !== imageCount) {
      toast.error(`Você selecionou ${uploadedImages.length} imagens, mas a quantidade esperada é de ${imageCount}.`);
      return;
    }

    toast.success("Imagens e container salvos com sucesso!");
    const formData = new FormData(); // Cria um formulário para o envio multipart
    formData.append("imageCount", imageCount); // Adiciona a quantidade esperada

    uploadedImages.forEach((file) => {
      formData.append("files", file); // Adiciona cada arquivo ao formulário
    });

    try {
      const response = await axios.post(`${parsedURL}post/cad_rel_photografic/${imageCount}?codacond=${selectedOption}&codos=${codOrder}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status === 200) {
        toast.success("Imagens carregadas com sucesso!");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Erro ao carregar imagens. Tente novamente.");
    }
  };

  return (
    <>
      <div className="modal fade" id="modal6" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Importar Imagens</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="card col-md-12 mx-auto">
                <div className="card-body">
                  <form className="row">
                    <div className="col-md-6">
                      <label htmlFor="inputState" className="col-form-label">
                        Selecione o Container
                      </label>
                      <select
                        id="inputState"
                        className="form-select"
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value="0" selected>
                          Selecione um Container
                        </option>
                        {containerList.map((container) => (
                          <option key={container.codacond} value={container.codacond}>
                            {container.nracond}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="imageCountOptions"
                          id="radio2"
                          value={2}
                          onChange={(e) => setImageCount(parseInt(e.target.value))}
                        />
                        <label className="form-check-label" htmlFor="radio2">
                          2 Imagens
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="imageCountOptions"
                          id="radio4"
                          value={4}
                          onChange={(e) => setImageCount(parseInt(e.target.value))}
                        />
                        <label className="form-check-label" htmlFor="radio4">
                          4 Imagens
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="imageCountOptions"
                          id="radio6"
                          value={6}
                          onChange={(e) => setImageCount(parseInt(e.target.value))}
                        />
                        <label className="form-check-label" htmlFor="radio6">
                          6 Imagens
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="imageCountOptions"
                          id="radio8"
                          value={8}
                          onChange={(e) => setImageCount(parseInt(e.target.value))}
                        />
                        <label class="form-check-label" htmlFor="radio8">
                          8 Imagens
                        </label>
                      </div>
                    </div>
                    <div><hr className="divider" /></div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="file"
                        name="formFile"
                        onChange={handleFileChange}
                        multiple
                        accept="image/*"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Fechar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveContainer}
              >
                Salvar Container
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatorioFModal;