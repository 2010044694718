import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";

const ConsultaOS = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
  
   
  const getConsultaOS = async () => {
    try {
      const response = await axios.get("https://www.balldontlie.io/api/v1/teams");
      setData(response.data.data.map(row => ({ ...row, selected: false })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getConsultaOS();
  }, []);

  const handleCheckboxChange = (id) => {
    setData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.id === id ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.selected}
          onChange={() => handleCheckboxChange(params.row.id)}
        />
      ),
    },
    { field: "city", headerName: "City", width: 150 },
    { field: "abbreviation", headerName: "Abbreviation", width: 150 },
    { field: "conference", headerName: "Conference", width: 150 },
    { field: "division", headerName: "Division", width: 150 },
  ];

  const rows = data.map((row) => ({
    id: row.id,
    abbreviation: row.abbreviation,
    city: row.city,
    conference: row.conference,
    division: row.division,
  }));

  console.log(data);

  return (
        <>
        <Navbar></Navbar>
        <div className="box-center">
            <div>
        <form class="row g-3">
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Ordem de Serviço</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-2">
      <label for="tipoentidade" class="form-label">Status</label>
      <select id="inputState" class="form-select ">
        <option selected></option>
        </select>
    </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Navio</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-1">
    <label for="razaosocial" class="form-label ">Viagem</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-1">
    <label for="razaosocial" class="form-label ">Booking</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-1">
    <label for="nomefantasia" class="form-label">Safra</label>
    <input type="text" class="form-control  " id="nomefantasia"/>
  </div>
  <div class="col-md-1">
    <label for="razaosocial" class="form-label ">Qtd Fardos</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Nr. Fardo</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="form-label my-2">
  <div class="row align-items-start">
    <fieldset class="row col-4">
    <legend className="form-label text-center ">Entre Datas de abertura de OS</legend>
    <div class="col-md-6">
    <input type="date" class="form-control " id="razaosocial"/>
    </div>
    <div class="col-md-6">
        <input type="date" class="form-control " id="razaosocial"/>
    </div>
    </fieldset>
    <div class="row col-6">
    <legend className="form-label ">Nome Fantasia da Entidade</legend>
    <div class="col-md-8">
    <input type="text" class="form-control " id="razaosocial"/>
    </div>
     <div class="col-md-4">
      <select id="inputState" class="form-select ">
        <option selected>CLIENTE</option>
        </select>
    </div>
    </div>
    <div class="row col-2">
    <legend className="form-label ">Container</legend>
    <div class="col-md-12">
    <input type="text" class="form-control " id="razaosocial"/>
        </div>
        </div>
        </div>
    </div>
    <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Instruçao de Embarque</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Serviço</label>
    <select id="inputState" class="form-select ">
        <option selected>Selecione</option>
    </select>
  </div>
  <div class="col-md-3">
    <label for="razaosocial" class="form-label ">Cidade de Execução do serviço</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-3">
    <label for="nomefantasia" class="form-label">Referência Cliente</label>
    <input type="text" class="form-control  " id="nomefantasia"/>
  </div>
  <div class="col-md-2">
    <label for="razaosocial" class="form-label ">Escritório</label>
    <select id="inputState" class="form-select ">
        <option selected>Selecione</option>
        </select>
  </div>
  <div class="form-label my-2">
  <div class="row align-items-start">
  <fieldset class="row col-4">
    <legend className="form-label text-center ">Entre Datas de Deadline docs.</legend>
    <div class="col-md-6">
    <input type="date" class="form-control " id="razaosocial"/>
    </div>
    <div class="col-md-6">
        <input type="date" class="form-control " id="razaosocial"/>
    </div>
    </fieldset>
    <div class="row col-8">
    <div class="col-md-8">
    <label for="razaosocial" class="form-label ">Destino</label>
    <select id="inputState" class="form-select ">
        <option selected>Selecione</option>
        </select>
  </div>
  <div class="col-md-3">
    <label for="razaosocial" class="form-label ">Usuário (Login)</label>
    <input type="text" class="form-control " id="razaosocial"/>
  </div>
  <div class="col-md-1">
    <label for="razaosocial" class="form-label ">ㅤ</label>
    <button type="button" class="btn btn-primary">Pesquisar</button>
    </div>
    </div>
    </div>
    </div>
  </form>
  <div><hr class="divider bg-custom "/></div>
  <div  className="my-3 transparent-input" style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
  </div>   
  </div>
        </>
  );
};

export default ConsultaOS;