import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import axios from "axios";

const AddArquivo = () => {
  const navigate = useNavigate();
  const typeUser = parseInt(localStorage.getItem("typeUser"));
  const token = localStorage.getItem("token");
  const [file,setFile] = useState(null);
  const parsedURL = localStorage.getItem('urlParsed');
  const [loading, setLoading] = useState(false);
  const [finalEndPoint, setFinalEndPoint] = useState('');
  const [idCelery, setIdCelery] = useState('');

  const handleAppendFile = (event) => {
    const arquivoSelecionado = event.target.files[0];
    setFile(arquivoSelecionado);
  };

  useEffect(() => {
    handleGetEndPoint();
  },[]);

  const handleGetEndPoint = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/integracao/end_point_excel_file`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setFinalEndPoint(response.data.url_end_point);
      }
    } catch (error) {
      console.log(error)
    }
  }
  

  const handleFileUpload = async () => {
    toast.info('Iniciando o cadastro do romaneio!', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setLoading(true);
  
    const formData = new FormData();
    formData.append('excel_file', file);
  
    try {
      const response = await axios.post(
        `${parsedURL}${finalEndPoint}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.status === 200) {
        const idCelery = response.data;
        let resp;
  
        // Loop para verificar o status da operação
        while (true) { // Loop infinito
          resp = await axios.get(
            `${parsedURL}get/status/${finalEndPoint}/${idCelery}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'accept': 'application/json',
              },
            }
          );
  
          if (resp.data !== null) {
            toast.success('Romaneio Cadastrado com Sucesso!!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            break; // Saia do loop quando resp.data não for nulo
          }
          
          // Aguarde um segundo antes de fazer outra solicitação (opcional)
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
  
        // Sucesso no envio do arquivo - faça algo com a resposta, se necessário
        setLoading(false);
  
      } else {
        // Lidar com códigos de status diferentes de 200
        setLoading(false);
  
        toast.error('Erro ao cadastrar o Romaneio', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
  
      // Lidar com erros durante a requisição
      toast.error('Erro ao concluir a operação!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  
  console.log(token)
  


  if (typeUser !== 120 && typeUser !== 100 && typeUser !== 90) {
    return null;
  }
  return (
        <><Navbar></Navbar>
        <div className="container box-center">
            <legend className="bg-custom">Integração de Arquivos</legend>
        <div class="mb-3">
            <label for="formFile" class="hfx my-2">Adicione aqui o seu arquivo:</label>
            <input class="form-control " type="file" id="file" onChange={handleAppendFile}/>
            </div>
            {loading && 
        <div class="d-flex align-items-center">
        <strong className='text-light' role="status">Loading...</strong>
        <div class="spinner-border ms-auto text-light" aria-hidden="true"></div>
      </div>}
        <div className="my-2">
        <button type="button" class="btn btn-success mx-2" onClick={handleFileUpload}>Salvar</button>
        </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        </>
  );
};

export default AddArquivo;