import React, { useState } from 'react';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../style/style.css";

const VizFinal1 = () => {
    const [rowData, setRowData] = useState([
        { mission: "Voyager", company: "NASA", location: "Cape Canaveral", date: "1977-09-05", rocket: "Titan-Centaur ", price: 86580000, successful: true, selected: false },
        { mission: "Apollo 13", company: "NASA", location: "Kennedy Space Center", date: "1970-04-11", rocket: "Saturn V", price: 3750000, successful: false, selected: false },
        { mission: "Falcon 9", company: "SpaceX", location: "Cape Canaveral", date: "2015-12-22", rocket: "Falcon 9", price: 9750000, successful: true, selected: false }
      ]);
    
       const handleCheckboxChange = (mission) => {
        setRowData((prevData) => {
          const updatedData = prevData.map((row) =>
            row.mission === mission ? { ...row, selected: !row.selected } : row
          );
          return updatedData;
        });
      };
    
      const handleAddRow = () => {
        const newMission = `New Mission ${rowData.length + 1}`;
    
        setRowData((prevData) => [
          ...prevData,
          {
            mission: newMission,
            company: "New Company",
            location: "New Location",
            date: "2024-01-08",
            rocket: "New Rocket",
            price: 0,
            successful: false,
            selected: false,
          },
        ]);
      };
      const colDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
        { field: "mission", headerName: "Mission", filter: true, width: 120 },
        { field: "company", headerName: "Company", filter: true, width: 100 },
        { field: "location", headerName: "Location", filter: true , width: 80},
        { field: "date", headerName: "Date", filter: true, width: 80 },
        { field: "price", headerName: "Price", filter: true, width: 80 },
        { field: "rocket", headerName: "Rocket", filter: true },
        {/* field: "rocket", headerName: "Rocket", filter: true, width: 70 , cellRenderer: (params) => (
          <button type="submit" class="btn btn-primary btn-sm" onClick={() => handleCheckboxChange(params.value)}><Search/></button>
        )*/}
      ];
    
      return (
        <>
                    <div className="ag-theme-quartz" style={{ height: 300 }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        rowSelection="multiple"
                        onSelectionChanged={() => console.log('Row Selected!')}
                    />
                    </div>
                    <div><hr class="divider bg-custom"/></div>
                    {/*<button type="submit" class="btn btn-primary" onClick={handleAddRow}>Adicionar Linha</button>*/}
                
        </>
      );
    };
    
    
export default VizFinal1 ;