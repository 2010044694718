import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import { Checkbox } from "@mui/material";
import "../../style/style.css"
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const FardosRepetidosProcesso = () => {
    const [data, setData] = useState([]);
  
   
    const getConsultaOS = async () => {

    };
  
    useEffect(() => {
      getConsultaOS();
    }, []);
  
    const handleCheckboxChange = (id) => {
      setData((prevData) => {
        const updatedData = prevData.map((row) =>
          row.id === id ? { ...row, selected: !row.selected } : row
        );
        return updatedData;
      });
    };
  
    const columns = [
      {
        field: "id",
        headerName: "ID",
        width: 90,
        renderCell: (params) => (
          <Checkbox
            checked={params.row.selected}
            onChange={() => handleCheckboxChange(params.row.id)}
          />
        ),
      },
      { field: "city", headerName: "City", width: 150 },
      { field: "abbreviation", headerName: "Abbreviation", width: 150 },
      { field: "conference", headerName: "Conference", width: 150 },
      { field: "division", headerName: "Division", width: 150 },
    ];
  
    const rows = data.map((row) => ({
      id: row.id,
      abbreviation: row.abbreviation,
      city: row.city,
      conference: row.conference,
      division: row.division,
    }));
  
    console.log(data);

  return (
        <>
        <div class="row mt-3">
          <div class="col-md-2">
          <label for="emailfat" class="form-label hfx">Fardos Repetidos</label>
          <input type="number" class="form-control transparent-input rounded" placeholder="0000" id="safra"/>
        </div>
        </div>
  	<div  className="my-3 transparent-input" style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
        <div><hr class="divider bg-custom"/></div>
        <div class="col-12">
          <button type="submit" class="btn btn-primary"><DoneOutlinedIcon/> Verificar</button>
          <button type="submit" class="btn mx-2 btn-warning"><DeleteOutlineIcon/> Limpar</button>
          <button type="submit" class="btn btn-success"><FileDownloadIcon/> Exportar Arquivo</button>
        </div>
        </>
  );
};

export default FardosRepetidosProcesso;