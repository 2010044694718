import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import { Checkbox, Button } from "@mui/material";
import ModalOS1 from "./modal_os";
import ModalOS2 from "./modal_os2";
import SearchIcon from '@mui/icons-material/Search';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';


const StatusOS = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const parsedURL = localStorage.getItem('urlParsed');// Estado para a opção selecionada
  const typeUsr = localStorage.getItem('typeUser');
  const [textOrder, setTextOrder] = useState('');
  const [order, setOrder] = useState('');
  const [columns, setColumns] = useState([

    { field: "os", headerName: "OS", width: 150 },
    { field: "cliente", headerName: "Cliente", width: 220 },
    { field: "terminal", headerName: "Terminal", width: 220 },
    {
      field: "ie",
      headerName: "Instrução de embarque",
      width: 220,
    },
    {
      field: "verCNTR",
      headerName: "Informações",
      width: 150,
      renderCell: (params) => (
        <button class="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" onClick={() => handleVerCNTRClick(params.row.codos, params.row.os)} variant="contained" color="primary">
          <ContentPasteSearchIcon />
        </button>
      ),
    },
  ]);
  const [codAcond, setCodAcond] = useState('');
  const [numberCC, setNumberCC] = useState('');


  const handleConsultListOrders = async () => {
    const token = localStorage.getItem('token');
    let apiUrl = '';
    console.log(typeUsr);
    switch (typeUsr) {
      case '90':
        apiUrl = `${parsedURL}get/lista_ordens/cliente`;
        break;
      case '120':
        apiUrl = `${parsedURL}get/lista_ordens/user`;
        break;
      case '100':
        apiUrl = `${parsedURL}get/lista_ordens/user`;
        break;
      case '110':
        apiUrl = `${parsedURL}get/lista_ordens/user`;
        break;
      default:
        break;
    }

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(response)
      setData(response.data);
      if (typeUsr !== '90') {
        // Adicionar a coluna "Ação" se o tipo de usuário for diferente de 90
        setColumns(prevColumns => ([
          ...prevColumns,
          {
            field: "aloca",
            headerName: "Ações",
            width: 150,
            width: 150,
            renderCell: (params) => (
              <button class="btn btn-primary" data-bs-target="#modal_action" data-bs-toggle="modal" onClick={() => handleVerCNTRClick(params.row.codos, params.row.os)} variant="contained" color="primary">
                <OpenInFullIcon />
              </button>

            ),
          }
        ]));
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    handleConsultListOrders();
  }, []);


  const handleVerCNTRClick = (id, textOrder) => {
    setOrder(id);
    setTextOrder(textOrder);
    };

  const handleConsultCntr = (id, nbrCC) => {
    setCodAcond(id);
    setNumberCC(nbrCC);
  };

  const rows = data.map((row, index) => ({
    id: index + 1,
    codos: row.codos,
    os: row.os,
    cliente: row.cliente,
    terminal: row.terminal,
    ie: row.ie,
  }));
  return (
    <>
      <Navbar />
      <div className="box-center">
        {/*<div class="input-group">
                <input type="text" class="form-control"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Procurar<SearchIcon className="mx-2"/></button>
  </div>*/}
        <div><hr className="divider bg-custom" /></div>
        <div className="my-3 transparent-input" style={{ height: 500, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
        <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Containers</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <ModalOS1
                  ord={order}
                  OrderText={textOrder}
                  handleConsultCntr={handleConsultCntr}
                ></ModalOS1>
              </div>
              {/*<div class="modal-footer">
                    <button class="btn btn-warning">Gerar Packing List</button>
                    <button class="btn btn-success">Gerar Relatório</button>
                    <button class="btn btn-danger">Fechar</button>
  </div>*/}
            </div>
          </div>
        </div>
        <div class="modal fade" id="modal_action" aria-hidden="true" aria-labelledby="modal_action" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="modal_action">Ações</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-footer">
              <Link  to={`/cadastro/os?codos=${order}`}>
                  <button class="btn btn-secondary" data-bs-dismiss="modal">Ordem de Servico</button></Link>
                <Link  to={`/cadastro/relatorio_fotografico?idos=${order}`}>
                  <button class="btn btn-secondary" data-bs-dismiss="modal">Relatório Fotgrafico</button></Link>
                  <Link  to={`/cadastro/planilha_peso?idos=${order}`}>
                  <button class="btn btn-secondary" data-bs-dismiss="modal">Planilha de Peso</button></Link>
                <Link to={`/cadastro/certificado?idos=${order}`}>
                  <button class="btn btn-secondary" data-bs-dismiss="modal">Certificado</button></Link>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">NF's e Lote</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <ModalOS2
                  codAcond={codAcond}
                  numberAcod={numberCC}
                ></ModalOS2>
              </div>
              <div class="modal-footer">
                <button class="btn btn-danger" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Voltar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default StatusOS;
