import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";
import { Checkbox } from "@mui/material";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import IntegraçãoOS from "./integracao_os";
import StatusObsOs from "./status_os1";
import DadosFat from "./dados_faturamento.jsx";
import LoteProc from "./lote_proc.jsx";
import Search from "@mui/icons-material/Search.js";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PesquisaEntidade from "./pesquisa_entidade.jsx";
import logoAgs from "../../assets/Logoag cp.jpg"
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const OS_Cadastro1 = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const codos = searchParams.get('codos');
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const codoffice = localStorage.getItem('codoffice');
  const [data, setData] = useState([
    {
      codos: "",
      codlote: "",
      lote: "",
      qtd: ""
    }
  ]);
  const [dataOS, setDataOs] = useState({
    os: "",
    dataos: "",
    codCliente: "",
    cliente: "",
    codProdutor: "",
    produtor: "",
    codExportador: "",
    exportador: "",
    codTerminal: "",
    terminal: "",
    codDespachante: "",
    despachante: "",
    codCorretor: "",
    corretor: "",
    codTrader: "",
    trader: "",
    codoffice: "",
    codcidade: "",
    coduser: "",
    nomeUser: "",
    faturar: "",
    codFaturarP: "",
    enviar: "",
    CodEnviarP: "",
    codservico: "",
    sta: "",
    situacao_fat: "",
    navio: "",
    viagem: "",
    deadDox: "",
    deadCargo: "",
    qtdFull: "",
    qtdPart: "",
    contrato: "",
    booking: "",
    sta_envioPeso: "",
    sta_envioDraft: "",
    sta_certificado: "",
    sta_finalizado: "",
    sta_pago: "",
    destino: "",
    obs: "",
    dtfat: "",
    cotacao: "",
    desconto: "",
    nf: "",
    obsfat: "",
    safra: "",
    ie: "",
    taxa: "",
    unidade: "",
    moeda: "",
    peso: "",
    limiteMin: "",
    limiteMax: "",
    limiteCntr: "",
    fardos: "",
    temCondition: "",
    dtcertificado: "",
    titulo: "",
    dtservico: "",
    nb: "",
    ref: "",
    custodia: "",
    nbCondition: "",
    tipolimite: "",
    integrada: "",
    dtultimo_dia_servico: "",
    arquivo_integracao: "",
    comentariofat: "",
    faturado: "",
    codos_origem: "",
    cod_destino: "",
    data_update: "",
    data_finish: "",
    data_envio_fat: "",
    coduserplan: "",
    userplanilha: ""
  });
  const [copyCPE, setCopyCPE] = useState(true);
  const [tipo, setTipo] = useState("");
  const [cidades, setCidade] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [grossChecked, setGrossChecked] = useState(false);
  const [netChecked, setNetChecked] = useState(false);
  const [enableCadLotes, setEnableCadLotes] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    handleGetCidadesServico();
    handleGetServico();
    handleGetDestinos();
    handleChangeTypeLimit();
    handelSetEnableCadLotes();
    if (codos == null) {
      setDataOs((prevDataOs) => ({
        ...prevDataOs, // mantenha o conteúdo atual
        codcidade: 2,
        codoffice: codoffice,
        cod_destino: 20,
        codservico: 1
      }));
      generateSafra();
    }
    else {
      handleGetLoteOs();
      handleGetOS();
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataOs(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeTypeLimit = () => {
    let type = dataOS.tipolimite;

    if (type == 'GROSS') {
      setGrossChecked(true);
    } else {
      setNetChecked(true);
    }

  };

  const handleGrossChange = () => {
    setGrossChecked(!grossChecked);
    setDataOs(
      {
        tipolimite: "GROSS"
      }
    );
    if (netChecked) {
      setNetChecked(false); // desmarcar o outro checkbox
    }
  };

  const handleNetChange = () => {
    setNetChecked(!netChecked);
    setDataOs(
      {
        tipolimite: "NET"
      }
    );
    if (grossChecked) {
      setGrossChecked(false); // desmarcar o outro checkbox
    }
  };

  const generateSafra = () => {
    const today = new Date();
    const ini = new Date(today.getFullYear(), 5, 31); // Mês é zero-based, então maio é 4
    const fim = new Date(today.getFullYear(), 6, 31);
    let safra;
    if (today > ini) {
      safra = today.getFullYear(); // Se for depois, a safra é do ano atual
    } else {
      safra = today.getFullYear() - 1; // Se não, a safra é do ano anterior
    }
    const Safra = safra.toString();
    setDataOs((prevDataOs) => ({
      ...prevDataOs, // mantenha o conteúdo atual
      safra: Safra, // atualize/adicione a propriedade 'safra'
    }));
  }

  const handleGetCidadesServico = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cad_os/cidades_servicos`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data));
        setCidade(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetServico = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cad_os/servicos`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data));
        setServicos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetLoteOs = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cad_os/lote_os?codos=${codos}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data));
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelSetEnableCadLotes = async () => {
    if (codos != null) {
      setEnableCadLotes(false);
    } else {
      setEnableCadLotes(true);

    }
  };

  const convertDateToISO = (dateStr) => {
    console.log(dateStr);
    if (!dateStr || typeof dateStr !== 'string') {
      // Retorna uma string vazia ou algum valor padrão para datas
      return '';
    }

    const parts = dateStr.split('/');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      if (day.length === 2 && month.length === 2 && year.length === 4) {
        return `${year}-${month}-${day}`;
      }
    }

    // Se o formato for inesperado, retorne uma string vazia ou lance um erro
    throw new Error('Invalid date format');
  };
  const handleGetOS = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cad_os/data_os?codos=${codos}`,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        // Convertendo datas antes de definir no estado
        data.deadDox = convertDateToISO(data.deadDox);
        data.deadCargo = convertDateToISO(data.deadCargo);
        setDataOs(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetDestinos = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cad_os/destinos`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data));
        setDestinos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSeach = (tipoBusca) => {
    setTipo(tipoBusca);
  };

  const handleCheckCopy = () => {
    setCopyCPE(!copyCPE);

  };

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${parsedURL}post/cad_os/os`,
        dataOS,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data));
        navigate(`/cadastro/os?codos=${response.data.codos}`);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const visualizarImpressao = () => {
    var documento = {
      content: [
        {
          alignment: 'justify',
          columns: [
            {
              stack: [

                {
                  text: [
                    { text: `Ordem de Serviço: `, bold: true, style: 'header' },
                    { text: `${dataOS.os}`, style: 'header' },
                  ]
                },
                {
                  text: [
                    { text: `Responsavel: `, bold: true, style: 'header' },
                    { text: `${dataOS.nomeUser}`, style: 'header' },
                  ]
                },
                {
                  text: [
                    { text: `Data de abertura: `, bold: true, style: 'header' },
                    { text: `${dataOS.dataos}`, style: 'header' },
                  ]
                },
              ]
            }
          ]
        },
      ],
      styles: {
        header: {
          fontSize: 11,
          alignment: 'left',
          margin: [0, 2, 0, 10]
        }
      },
      images: {
        agsLog: 'Logoag cp.jpg'
      },
      info: {
        title: 'Ordem de Serviço',
        author: 'AG Surveyors',
        },

    }


    pdfMake.createPdf(documento).open({}, window.open('', '_blank'))
  };


  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${parsedURL}put/cad_os/os?codos=${codos}`,
        dataOS,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdateLotes = async () => {
    try {
      const response = await axios.put(
        `${parsedURL}put/cad_os/lotes_as_os?codos=${codos}`,
        data,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleButtonSave = () => {
    if (codos != null) {
      handleUpdate();
      if (data != null){
        handleUpdateLotes();
      };
    } else {
      handleSave();
    }
  }
  const columns = [
    { field: "lote", headerName: "Lote", width: 120, filter: true },
    { field: "qtd", headerName: "Qtd Fardos", width: 150, type: 'agSetColumnFilter', cellEditor: 'agNumberCellEditor', editable: true, filter: true },
    { field: "mark", headerName: "Marca", width: 150 },
    { field: "contrato", headerName: "Contrato", width: 150 },
    { field: "safra", headerName: "Safra", width: 150 },
    { field: "tara", headerName: "Tara", width: 150 },
    { field: "produtor", headerName: "Produtor", width: 150 },
    { field: "tipofardo", headerName: "Tipo de Fardo", width: 150 },
  ];



  return (<>
    <Navbar></Navbar>
    <div className="box-center">
      <nav>
        <div>
          <div class="row p-4">
            <div class="col-2">
              <label class="hfx form-label">Ordem de Serviço</label>
              <div class="input-group">
                <input type="text" readOnly={true} value={dataOS.os} class="form-control transparent-input" />
              </div>
            </div>
            <div class="col-2">
              <label class="hfx form-label">Status</label>
              <div class="input-group">
                <input type="text" readOnly={true} value={dataOS.sta} class="form-control transparent-input" />
              </div>
            </div>
            <div class="col-2">
              <label class="hfx form-label">Criada em</label>
              <div class="input-group">
                <input type="text" readOnly={true} value={dataOS.dataos} class="form-control transparent-input" />
              </div>
            </div>
            <div class="col-3">
              <label class="hfx form-label">Criada por </label>
              <div class="input-group">
                <input type="text" readOnly={true} value={dataOS.nomeUser} class="form-control transparent-input" />
              </div>
            </div>
            <div class="col-3">
              <label class="hfx form-label">Enviada por</label>
              <div class="input-group">
                <input type="text" readOnly={true} value={dataOS.userplanilha} class="form-control transparent-input" />
              </div>
            </div>
          </div>
        </div>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link active form-label" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-cadastro" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Cadastro de OS</button>
          <button class="nav-link form-label" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-status" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Status - Observação - Despesas Adicionais</button>
          <button class="nav-link form-label" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-faturamento" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Dados Faturamento</button>
          <button class="nav-link form-label" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-integracao" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Integração</button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-cadastro" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
          <form class="row g-3 py-2 my-2">
            <div class="container">
              <div class="row align-items-start">
                <div class="form-check form-check-reverse">
                  <input class="form-check-input mx-1" type="checkbox" id="reverseCheck1" checked={copyCPE} onChange={handleCheckCopy} />
                  <label class="form-check-label hfx mx-1" for="reverseCheck1">Copiar</label>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label class="hfx form-label">Cliente</label>
                    <div class="input-group">
                      <input type="text" value={dataOS.cliente} required={true} readOnly={true} class="form-control transparent-input" />
                      <button type="button" onClick={() => handleClickSeach("cliente")} class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="hfx form-label">Exportador</label>
                    <div class="input-group">
                      <input type="text" value={dataOS.exportador} required={true} readOnly={true} class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon2" />
                      <button type="button" disabled={copyCPE} onClick={() => handleClickSeach("exportador")} class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="hfx form-label">Despachante</label>
                    <div class="input-group">
                      <input type="text" value={dataOS.despachante} required={true} readOnly={true} class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon2" />
                      <button type="button" onClick={() => handleClickSeach("despachante")} class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="hfx form-label">Trader</label>
                    <div class="input-group">
                      <input type="text" value={dataOS.trader} readOnly={true} class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon2" />
                      <button type="button" onClick={() => handleClickSeach("trader")} class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                    </div>
                  </div>
                </div>
                <div class="row col">
                  <div class="mb-3">
                    <label class="hfx form-label">Produtor</label>
                    <div class="input-group">
                      <input type="text" value={dataOS.produtor} required={true} readOnly={true} class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon2" />
                      <button type="button" disabled={copyCPE} onClick={() => handleClickSeach("produtor")} class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="hfx form-label">Terminal</label>
                    <div class="input-group">
                      <input type="text" value={dataOS.terminal} readOnly={true} class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon2" />
                      <button type="button" onClick={() => handleClickSeach("terminal")} class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="hfx form-label">Corretor</label>
                    <div class="input-group">
                      <input type="text" value={dataOS.corretor} readOnly={true} class="form-control transparent-input" aria-label="Input group example" aria-describedby="btnGroupAddon2" />
                      <button type="button" onClick={() => handleClickSeach("corretor")} class="input-group-text btn-primary btn " data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Search /></button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label hfx">Navio</label>
                    <input value={dataOS.navio} type="text" class="form-control transparent-input" name="navio" onChange={handleChange} />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label hfx">Viagem</label>
                    <input value={dataOS.viagem} type="text" class="form-control transparent-input" name="viagem" onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <label class="form-label">Instrução de embarque</label>
              <input required={true} value={dataOS.ie} type="text" class="form-control transparent-input" name="ie" onChange={handleChange} />
            </div>
            <div class="col-md-2">
              <label class="form-label">Referência Cliente</label>
              <input type="text" required={true} value={dataOS.ref} class="form-control transparent-input" name="ref" onChange={handleChange} />
            </div>
            <div class="col-md-2">
              <label class="form-label">DeadLine Docs.</label>
              <input type="date" value={dataOS.deadDox} class="form-control transparent-input" name="deadDox" onChange={handleChange} />
            </div>
            <div class="col-md-2">
              <label class="form-label">DeadLine Carga</label>
              <input type="date" value={dataOS.deadCargo} class="form-control transparent-input" name="deadCargo" onChange={handleChange} />
            </div>
            <div class="col-md-2">
              <label class="form-label">Booking</label>
              <input type="text" value={dataOS.booking} class="form-control transparent-input" name="booking" onChange={handleChange} />
            </div>
            <div class="col-md-1">
              <label class="form-label">Contrato</label>
              <input type="text" value={dataOS.contrato} class="form-control transparent-input" name="contrato" onChange={handleChange} />
            </div>
            <div class="col-md-1">
              <label required={true} class="form-label">Safra</label>
              <input type="text" value={dataOS.safra} class="form-control transparent-input" name="safra" onChange={handleChange} />
            </div>
            <div class="col-md-1">
              <label class="form-label">Qtd. Cheios</label>
              <input required={true} value={dataOS.qtdFull} type="number" class="form-control transparent-input" name="qtdFull" onChange={handleChange} />
            </div>
            <div class="col-md-1">
              <label class="form-label">Qtd. Parts</label>
              <input value={dataOS.qtdPart} type="number" class="form-control transparent-input" name="qtdPart" onChange={handleChange} />
            </div>
            <div class="col-md-1">
              <label class="form-label">Fardos</label>
              <input required={true} value={dataOS.fardos} type="number" class="form-control transparent-input" name="fardos" onChange={handleChange} />
            </div>
            <div class="col-md-3">
              <label required={true} class="form-label">Cidade Execução do serviço</label>
              <select id="inputState"
                name="codcidade"
                value={dataOS.codcidade}
                class="form-select transparent-input"
                onChange={handleChange}
              >
                {cidades.map((citys) => (
                  <option key={citys.codcidade} value={citys.codcidade} >
                    {citys.cidade}
                  </option>
                ))}
              </select>
            </div>
            <div class="col-md-2">
              <label required={true} class="form-label">Destino</label>
              <select id="inputState"
                name="cod_destino"
                value={dataOS.cod_destino}
                class="form-select transparent-input"
                onChange={handleChange}
              >
                {destinos.map((dest) => (
                  <option key={dest.id} value={dest.id} >
                    {dest.destino_pt}
                  </option>
                ))}
              </select>
            </div>
            <div class="col-md-4">
              <label required={true} class="form-label">Serviço</label>
              <select id="inputState"
                name="codservico"
                value={dataOS.codservico}
                class="form-select transparent-input"
                onChange={handleChange}
              >{servicos.map((service) => (
                <option key={service.codservico} value={service.codservico} >
                  {service.servico}
                </option>
              ))}
              </select>
            </div>
            <div class="box-center overflow-hidden">
              <div class="row">
                <div class="col-md-2">
                  <label required={true} class="form-label">Faturado</label>
                  <select id="inputState" value={dataOS.faturado} onChange={handleChange} class="form-select transparent-input" name="faturado">
                    <option value={0} selected>Não</option>
                    <option value={1}>Pronto</option>
                    <option value={2}>Sim</option>
                    <option value={3}>Cancelado</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label class="form-label">NR NF Fatura</label>
                  <input value={dataOS.nf} onChange={handleChange} type="text" class="form-control transparent-input" name="nf" />
                </div>
              </div>
            </div>
            <div><hr class="divider bg-custom " /></div>
            <div class="box-center overflow-hidden">
              <div class="row">
                <div class="col-md-2 p-2">
                  <label class="form-label ">Peso Instrução</label>
                  <input type="number" value={dataOS.peso} onChange={handleChange} class="form-control transparent-input" step="0.01" name="peso" />
                </div>
                <div class="col-md-3 p-2">
                  <label class="form-label">Limite Total Mínimo</label>
                  <input type="number" value={dataOS.limiteMin} onChange={handleChange} class="form-control transparent-input" step="0.01" name="limiteMin" />
                </div>
                <div class="col-md-3 p-2">
                  <label class="form-label">Limite Total Máximo</label>
                  <input type="number" value={dataOS.limiteMax}  onChange={handleChange} class="form-control transparent-input" step="0.01" name="limiteMax" />
                </div>
                <div class="col-md-2 p-2">
                  <label class="form-label">Limite de Conteiners</label>
                  <input type="number" value={dataOS.limiteCntr} onChange={handleChange} class="form-control transparent-input" step="0.01" name="limiteCntr" />
                </div>
                <div class="col-md-2 p-2">

                  <label lass="form-label">ㅤ</label>
                  <div class="form-check">
                    <input onChange={handleGrossChange} class="form-check-input" checked={grossChecked} type="radio" />
                    <label class="form-check-label text-white" for="flexRadioDefault1" >
                      Gross
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2 p-2">
                  <label class="form-label">%</label>
                </div>
                <div class="col-md-3 p-2">
                  <input type="number" class="form-control transparent-input" id="inputPercent" min="0" max="100" step="0.01" />
                </div>
                <div class="col-md-3 p-2">
                  <input type="number" class="form-control transparent-input" id="inputPercent" min="0" max="100" step="0.01" />
                </div>
                <div class="col-md-2 p-2">
                  <input type="number" class="form-control transparent-input" id="inputPercent" min="0" max="100" step="0.01" />
                </div>
                <div class="col-md-2 p-2">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" onChange={handleNetChange} checked={netChecked} name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label text-white" for="flexRadioDefault1">
                      Net
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4  ">
              <div><hr class="divider bg-custom " /></div>
              <div className="my-3 ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                  rowData={data}
                  columnDefs={columns}
                  suppressMenuHide={true}
                  rowSelection="unique"
                />
              </div>
              <button type="button" disabled={enableCadLotes} class="btn btn-success my-2" data-bs-toggle="modal" data-bs-target="#LoteProc">
                <FormatListBulletedIcon /> Cadastrar Lotes
              </button>
            </div>
            <LoteProc
              oncodos={codos}
              ondataos={dataOS}
            />
          </form>
          <div class="col-12">
            <button type="button" class="btn btn-primary" onClick={handleButtonSave}>Salvar</button>
            <button type="button" class="btn mx-2 btn-warning" onClick={visualizarImpressao}>Imprimir</button>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-status" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
          <StatusObsOs
            ondataos={dataOS}
            onhandleChange={handleChange}
            onSetDataOs={setDataOs}
            onbuttonSave={handleButtonSave}
          />
        </div>
        <div class="tab-pane fade" id="nav-faturamento" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
          <DadosFat
            ondataos={dataOS}
            onSetDataOs={setDataOs}
            onhandleChange={handleChange}
            onhandleClickSeach={handleClickSeach}
            onbuttonSave={handleButtonSave}
          />
        </div>
        <div class="tab-pane fade" id="nav-integracao" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
          <IntegraçãoOS
            ondataos={dataOS}
            onSetDataOs={setDataOs}
            onhandleChange={handleChange}
            onbuttonSave={handleButtonSave}
          />
        </div>
      </div>
      <PesquisaEntidade
        ontipo={tipo}
        onSetDataOs={setDataOs}
        onCopyCPE={copyCPE}
      />
    </div>
  </>
  );
};

export default OS_Cadastro1;