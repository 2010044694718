import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"


const BuscarDados = () => {
  const navigate = useNavigate();

  return (
        <>
        <div class="modal fade" id="buscardados" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Buscar Dados</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <form className="row g-3 my-1">
      <div class="row align-items-start">
      <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Cliente</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Produtor</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Exportador</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Terminal</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Despachante</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Corretor</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Trader</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Navio</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Viagem</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">COFINS</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">IR</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Instrução de embarque</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Referência Cliente</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">DeadLine Docs.</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">DeadLine Carga</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Booking</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Contrato</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Safra</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Qtd. Cheios</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Qtd Parts</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Fardos</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Destino</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Faturado</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
                <div class="col-md-3">
                    <label for="razaosocial" class="form-label hfx3 ">Num NF Fatura</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial"/>
                </div>
               
      </div>
      </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary">OK</button>
      </div>
    </div>
  </div>
</div>
        
        </>
  );
};

export default BuscarDados;