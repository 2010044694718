import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";


const TransferirContainer = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  
   
  const getConsultaOS = async () => {
    try {
      const response = await axios.get("https://www.balldontlie.io/api/v1/teams");
      setData(response.data.data.map(row => ({ ...row, selected: false })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getConsultaOS();
  }, []);

  const handleCheckboxChange = (id) => {
    setData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.id === id ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.selected}
          onChange={() => handleCheckboxChange(params.row.id)}
        />
      ),
    },
    { field: "city", headerName: "City", width: 150 },
    { field: "abbreviation", headerName: "Abbreviation", width: 150 },
    { field: "conference", headerName: "Conference", width: 150 },
    { field: "division", headerName: "Division", width: 150 },
  ];

  const rows = data.map((row) => ({
    id: row.id,
    abbreviation: row.abbreviation,
    city: row.city,
    conference: row.conference,
    division: row.division,
  }));

  console.log(data);

  return (
        <>
        <Navbar></Navbar>
        <div className="container mt-4 p-0">
        <div className="card col-md-12 mx-auto">
          <div className="card-body">
          <div class="container">
            <div class="row align-items-start">
                <div class="col">
                <form>
                    <div class="mb-3">
                        <label for="inputEmail3" class="col-sm-12 col-form-label">Os de origem</label>
                        <div class="input-group">
                        <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                        <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Procurar</button>
                        </div>
                    </div>
            <div class="row mb-3">
                <label for="inputEmail3" class="col-sm-3 col-form-label">I.E:</label>
                <div class="col-sm-9">
                <input type="text" class="form-control transparent-input" id="inputEmail3" />
                </div>
            </div>
            <div class="row mb-3">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Cliente:</label>
                <div class="col-sm-9">
                <input type="text" class="form-control transparent-input" id="inputEmail3" />
                </div>
            </div>
            <div class="row mb-3">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Fardos:</label>
                <div class="col-sm-9">
                <input type="text" class="form-control transparent-input" id="inputEmail3" />
                </div>
            </div>
            <div class="row mb-3">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Containers:</label>
                <div class="col-sm-9">
                <input type="text" class="form-control transparent-input" id="inputEmail3" />
                </div>
            </div>
            </form>
                </div>
                <div class="col">
                    <form>
                        <div class="mb-3">
                            <label for="inputEmail3" class="col-sm-12 col-form-label">Os de Destino</label>
                            <div class="input-group">
                            <input type="text" class="form-control transparent-input"  aria-label="Input group example" aria-describedby="btnGroupAddon2"/>
                            <button class="input-group-text btn-primary btn" id="btnGroupAddon2">Procurar</button>
                            </div>
                        </div>
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-3 col-form-label">I.E:</label>
                    <div class="col-sm-9">
                    <input type="text" class="form-control transparent-input" id="inputEmail3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-3 col-form-label">Clientes:</label>
                    <div class="col-sm-9">
                    <input type="text" class="form-control transparent-input" id="inputEmail3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-3 col-form-label">Fardos:</label>
                    <div class="col-sm-9">
                    <input type="text" class="form-control transparent-input" id="inputEmail3" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-3 col-form-label">Containers:</label>
                    <div class="col-sm-9">
                    <input type="text" class="form-control transparent-input" id="inputEmail3" />
                    </div>
                </div>
                </form>
                </div>
            </div>
            <div><hr class="divider bg-custom "/></div>
        <div  className="my-3 transparent-input" style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
            </div>
          <form>
            <div className="text-center">
            <button type="submit" class="btn btn-primary">Transferir Containers Selecionados</button>
            </div>
            </form>
          </div>
        </div>
      </div>
        </>
  );
};

export default TransferirContainer;